import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridApi, GridReadyEvent, ValueGetterParams } from '@ag-grid-community/core'
import { ApolloError, gql, useQuery } from '@apollo/client'
import { compact, first, groupBy, uniqBy, sortBy, find, get, intersection, isEqual, clone, remove, uniq } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Table, UncontrolledDropdown } from 'reactstrap'
import { StoplightColumnDef } from '../../../helpers/columnDefs'
import { downloadWithFilename } from '../../../helpers/download'
import { statusColor, statusEnumToValue, stoplightManagerLinkRenderer } from '../../../helpers/helpers'
import { expandList, listExpanded } from '../../../helpers/list'
import { DashboardStoplightFragment, ListDetailFragment, ResearchCategoryCode, useDashboardStoplightQuery, DashboardStoplightStatusDetailFragment, useDownloadStoplightReportLazyQuery, VehicleCategoryCode, DashboardStoplightStatusFragment, CallanDateType, useRunIagReportMutation, ZipArchiveFile, DocumentType, DocumentSubType, FilesZipFragment, useGetZipArchiveLazyQuery, ReportsListFragment, useListSimpleQuery, ReportsFragment, ReportCommonClientPortfolioFragment, ComponentType } from '../../../__generated__/graphql'
import { AggregatedComponentProps, BaseComponent, ErrorComponent, FootnotableComponent, IndividualComponentProps, LoadingComponent, OptionsModal, TemplateComponent, ToolbarProps, getReportLinks } from '../../Report/Shared/ReportComponent'
import { JobPoller } from '../../Shared/JobPoller'
import { GuardModal, ModalScript } from '../../Shared/RouteLeavingGuard'
import SortableTable from '../../Shared/SortableTable'
import { FormInput } from '../../ui/Forms/FormInput'
import { ConsultantDashboardAggregatedComponentProps, ConsultantDashboardBaseComponent, ConsultantDashboardIndividualComponentProps } from './ConsultantDashboardComponent'
import { DATE_API_FORMAT } from '../../../helpers/constant';
import { appDate } from '../../../Context/CalendarContext';
import { splitReportList, expandableReportList, listReportExpanded } from '../../../helpers/report';
import classNames from 'classnames';
import { ApprovalsReportDisplay } from '../../Report/Shared/ReportApprovals';
import { ReportDisplayType } from '../../Report/Shared/ReportComponent';
import { ReportEditField, AggregatedReportEditProps, ReportEditInfoFields, ReportEditInfoTotalField, ReportEditTemplate, SimpleReportEdit, AdditionalReportingEdit, ReportEditHeading, ReportEditInstances } from '../../Report/Shared/ReportEdit';
import { DocumentNode } from 'graphql'

const Stoplight: React.FC<ConsultantDashboardAggregatedComponentProps> = (props) => {
  return (
    <ConsultantDashboardBaseComponent<DashboardStoplightFragment>
      {...props}
      expectedTypename={"Stoplight"}
      reactComponent={StoplightDisplay}
    />
  )
}

export const StoplightReport: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<DashboardStoplightFragment>
      {...props}
      expectedTypename={"Stoplight"}
      reactComponent={StoplightReportDisplay}
    />
  )
}

const urlErrorMessageScript: ModalScript = {
  header: "Create Stoplight Error",
  body: "An error occurred, and the stoplight could not be generated.",
  leaveButtonContent: "",
  stayButtonContent: "Confirm"
}

const StoplightDisplay:React.FC<ConsultantDashboardIndividualComponentProps<DashboardStoplightFragment>> = (props) => {
  const {component, auth, settings} = props
  const name = component.name || ""
  const queryVariables = { listId: settings.list?.id || 0, clientId: settings.client?.id || 0, dateType: CallanDateType.IAG_CURRENT_QUARTER }
  const shouldShowReports = settings.client?.id === 16467
  const [showLastUpdated, setShowLastUpdated] = useState(settings.showLastUpdated || false)
  const [search, setSearch] = useState("")
  const [rows, setRows] = useState([])
  const [runningJobs, setRunningJobs] = useState([])
  const [downloadingQuery, setDownloadingReports] = useState<DocumentNode>()
  const [downloadingSeries, setDownloadingSeries] = useState<string[]>([])
  const [urlErrorOpen, setUrlErrorOpen] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Manager',
      minwidth: 300,
      width: 340,
      sortable: true,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: stoplightManagerLinkRenderer,
        checkbox: shouldShowReports,
      },
      valueGetter: (params:ValueGetterParams) => {
        if(params.data) {
          return params.data?.manager?.name
        } else {
          if(params.node?.groupData){
            if(settings.useListOrder){
              return params.node.groupData['ag-Grid-AutoColumn']
            }
            return params.node.groupData['ag-Grid-AutoColumn'].split("|", 2)[1]
          }
        }
      },
    };
  }, []);
  const getDataPath = useCallback((data) => {
    return data.group;
  }, []);

  const { loading, data, error } = useDashboardStoplightQuery({
    fetchPolicy: "no-cache",
    variables: queryVariables
  })

  const [getStoplightUrl] = useDownloadStoplightReportLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data.download?.url){
        const downloadLink = document.createElement("a")
        downloadLink.href = data.download.url
        downloadLink.download = "Stoplight"
        downloadLink.click()
      } else {
        setUrlErrorOpen(true)
      }
    },
    onError: (error: ApolloError) => {
      setUrlErrorOpen(true)
    }
  });

  const downloadStoplight = () => {
    getStoplightUrl({variables: {args: { list: settings.list?.id || 0, client: settings.client?.id || 0, useListOrder: settings.useListOrder }}})
  }

  const setSelectedRows = (rows: any) => {
    setRows(rows)
  }

  const onRowSelected = (node: any, data: any) => {
    if(data){
      return
    }
    if(node?.childrenAfterFilter){
      node.childrenAfterFilter.forEach((child: any) => {
        if(child){
          child.setSelected(node.selected)
        }
      })
    }
  }

  const generateReports = () => {
    setRunningJobs((runningJobs) => {
      return [...runningJobs, ...rows]
    })
  }

  const downloadReports = () => {
    const generateQuery = () => {
      let query = ""

      rows.forEach((row: any, idx) => {
        const series = `product${idx}`

        query += `
          ${series}: product(id: ${row?.product?.product?.id}) {
            product {
              id
              documents {
                id
                versionId
                type {
                  code
                }
                subType {
                  code
                }
              }
            }
          }
        `
      })

      return query
    }

    const fetchDocumentsQuery = gql`
      query fetchDocuments {
        ${generateQuery()}
      }
    `

    setDownloadingReports(fetchDocumentsQuery)
    setDownloadingSeries(rows.map((row: any, idx) => {
      return `product${idx}`
    }))
    if(gridApi){
      gridApi.deselectAll()
    }
  }

  if(loading) return <LoadingComponent name={name}/>
  if(error) return <ErrorComponent name={name} error={error.message}/>
  if(!data?.list) return <ErrorComponent name={name} error={"No data"}/>

  const groupedStatuses = groupBy(data?.statusesForList, 'vehicle.vehicle.id')

  let rowData
  let rowId:string = "vehicle.id"

  if(settings.useListOrder) {
    const expandedList = expandList(data.list as ListDetailFragment)

    const iterateList = (list: listExpanded[], hierarchy: string[]):any => {
      return list.flatMap(item => {
        const name = item.item.manager?.name || item.name || ""
        let baseItems:any[] = []
        let OpinionHash:{[key:string]:DashboardStoplightStatusDetailFragment} = {}
        let statusInfo
        if("vehicle" in item.item){
          statusInfo = first(groupedStatuses[item.item.vehicle.id])
        } else if ("product" in item.item){
          const separateVehicle = find(item.item.product.vehicles, (vehicle) => vehicle.vehicle.category.code === VehicleCategoryCode.s)
          if(separateVehicle && separateVehicle.vehicle?.id) statusInfo = first(groupedStatuses[separateVehicle.vehicle.id])
        } else if ("relatedVehicle" in item.item && item.item.relatedVehicle?.vehicle?.id) {
          const performanceSource = item.item?.performanceSource?.code || "NO"
          const hideRow = !!item.item ? (moment(item.item?.serviceEndDate, DATE_API_FORMAT) < moment(appDate, DATE_API_FORMAT)) || (moment(item.item?.serviceStartDate, DATE_API_FORMAT).endOf('month') >= moment(appDate, DATE_API_FORMAT).endOf('month')) : false

          if(performanceSource === "HIDE" || hideRow){
            return []
          }
          statusInfo = first(groupedStatuses[item.item.relatedVehicle.vehicle.id])
        }


        if(statusInfo) {
          statusInfo.statuses?.forEach((opinion:DashboardStoplightStatusDetailFragment | null) => {
            if(opinion?.researchCategory &&
              (
                !OpinionHash[opinion.researchCategory] ||
                moment(OpinionHash[opinion.researchCategory]?.lastChangedDate).isBefore(moment(opinion.lastChangedDate
              )))){
              OpinionHash[opinion.researchCategory] = opinion
            }
          })

          baseItems.push({
            group: [...hierarchy, name + " - " + statusInfo.vehicle?.vehicle?.id], // Make the name uniq to deal with duplicated managers
            vehicle: statusInfo.vehicle?.vehicle,
            manager: statusInfo.manager,
            product: statusInfo.product,
            managerOverall: OpinionHash[ResearchCategoryCode.OOVER],
            managerPeople: OpinionHash[ResearchCategoryCode.OPEO],
            productOverall: OpinionHash[ResearchCategoryCode.POVER],
            productPeople: OpinionHash[ResearchCategoryCode.PPEO],
            longTerm: OpinionHash[ResearchCategoryCode.PERF],
            shortTerm: OpinionHash[ResearchCategoryCode.STPERF],
            philosophy: OpinionHash[ResearchCategoryCode.PHIL],
            dynamics: OpinionHash[ResearchCategoryCode.CONST],
          })
        }

        if(item.subGroup){
          return [...baseItems, ...iterateList(item.subGroup, [...hierarchy, name])]
        }
        return baseItems
      })
    }

    rowData = iterateList(expandedList, [])
  } else {
    rowData = compact(data.list.items?.map(item => {

      let OpinionHash:{[key:string]:DashboardStoplightStatusDetailFragment} = {}
      let statusInfo:DashboardStoplightStatusFragment | undefined
      if(item.item && "vehicle" in item.item && item.item.vehicle?.id){
        statusInfo = first(groupedStatuses[item.item.vehicle?.id]) || undefined
      } else if (item.item && "product" in item.item && item.item.product?.id){
        const separateVehicle = find(item.item.product.vehicles, (vehicle) => vehicle?.vehicle?.category?.code === VehicleCategoryCode.s)
        statusInfo = separateVehicle?.vehicle?.id ? first(groupedStatuses[separateVehicle.vehicle?.id]) || undefined : undefined
      } else if (item.item && "relatedVehicle" in item.item && item.item.relatedVehicle?.vehicle?.id) {
        const performanceSource = item.item?.performanceSource?.code || "NO"
        const hideRow = !!item.item ? (moment(item.item?.serviceEndDate, DATE_API_FORMAT) < moment(appDate, DATE_API_FORMAT)) || (moment(item.item?.serviceStartDate, DATE_API_FORMAT).endOf('month') >= moment(appDate, DATE_API_FORMAT).endOf('month')) : false

        if(performanceSource === "HIDE" || hideRow){
          return null
        }
        statusInfo = first(groupedStatuses[item.item.relatedVehicle.vehicle.id]) || undefined
      }

      if(statusInfo) {
        statusInfo.statuses?.forEach((opinion:DashboardStoplightStatusDetailFragment | null) => {
          if(opinion?.researchCategory &&
            (
              !OpinionHash[opinion.researchCategory] ||
              moment(OpinionHash[opinion.researchCategory]?.lastChangedDate).isBefore(moment(opinion.lastChangedDate
            )))){
            OpinionHash[opinion.researchCategory] = opinion
          }
        })

        return{
          group: [statusInfo.product?.product?.assetClass?.order + "|" + statusInfo.product?.product?.assetClass?.fullName, statusInfo.manager?.name + " - " + statusInfo.vehicle?.vehicle?.id], // Make the name uniq to deal with duplicated managers
          vehicle: statusInfo.vehicle?.vehicle,
          manager: statusInfo.manager,
          product: statusInfo.product,
          managerOverall: OpinionHash[ResearchCategoryCode.OOVER],
          managerPeople: OpinionHash[ResearchCategoryCode.OPEO],
          productOverall: OpinionHash[ResearchCategoryCode.POVER],
          productPeople: OpinionHash[ResearchCategoryCode.PPEO],
          longTerm: OpinionHash[ResearchCategoryCode.PERF],
          shortTerm: OpinionHash[ResearchCategoryCode.STPERF],
          philosophy: OpinionHash[ResearchCategoryCode.PHIL],
          dynamics: OpinionHash[ResearchCategoryCode.CONST],
        }
      }
      return null
    }))
    rowData = sortBy(rowData, (row) => {
      return [first(row.group)?.split("|", 2)[0].padStart(6, '0'), settings.showVehicle ? row.vehicle?.vehicleName : row.product?.product?.name]
    })
  }

  if(!settings.showVehicle){
    rowData = uniqBy(rowData, (row:any) => row?.product?.product?.id)
    rowId = "product.product.id"
  }

  console.log(315, {rowData})
  const colDef = StoplightColumnDef(settings, showLastUpdated)
  const onReady = (event:GridReadyEvent) => {
    setGridApi(event.api)
  }
  return (
    <>
      <div className="pane pane-toolbar sticky-top above-picker w-100">
        <Form className="mr-2 pr-3">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              type="text"
              placeholder="Search Results"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        {auth.checkPermissions(["view:expand_view"]) &&
          <div style={{width: 256}}>
            <FormInput
              property={'showLastUpdated'}
              displayName={"Expanded View"}
              type={'checkbox'}
              subtype={'show'}
              idx={`show-last-updated`}
              editMode={true}
              propertyVal={showLastUpdated}
              updateValue={(value) => {setShowLastUpdated(value)}}
            />
          </div>
        }
        <Button className="btn" color="secondary" onClick={downloadStoplight}>
          Export XLSX
          <img src='/assets/XLS.svg' className="ml-2"/>
        </Button>
        {shouldShowReports &&
          <Button className="btn ml-3" color="secondary" onClick={downloadReports}>
            {downloadingQuery && gridApi &&
              <DownloadReports query={downloadingQuery} downloadingSeries={downloadingSeries} setDownloadingReports={setDownloadingReports} setDownloadingSeries={setDownloadingSeries}/>
            }
            Download Reports
            <img src='/assets/PDF.svg' className="ml-2"/>
          </Button>
        }
        {auth.checkPermissions(['run:job']) && shouldShowReports &&
          <Button className="btn ml-3" color="secondary" onClick={generateReports}>
            Run Reports
          </Button>
        }
        <GuardModal
          key={`stoplight-url-error-modal`}
          open={!!urlErrorOpen}
          stay={() => setUrlErrorOpen(false)}
          leave={() => setUrlErrorOpen(false)}
          script={urlErrorMessageScript}
          hide={{leaveButton: true}}
        />
      </div>
      <div className={"pane pane-table p-0 w-100 view-port-80 d-flex flex-grow-1 flex-direction-column"}>
          <SortableTable
            loading={loading}
            filterText={search}
            columnDefs={colDef}
            tableData={rowData}
            autoGroupColumnDef={autoGroupColumnDef}
            treeData={true}
            getDataPath={getDataPath}
            setSelectedRows={setSelectedRows}
            onRowSelected={onRowSelected}
            onReady={onReady}
            rowId={rowId}
          />
      </div>
      {shouldShowReports && gridApi && runningJobs && runningJobs.map((row:any, idx) => (
        <React.Fragment key={row.vehicle.id +"-"+idx}>
          <IagReportPoller
            id={row.vehicle.id}
            gridApi={gridApi}
            onJobStatusUpdated={(status) => console.log(status)}
          />
          {row.vehicle.UMAProxyFundid &&
            <IagReportPoller
              id={row.vehicle.UMAProxyFundid}
              gridApi={gridApi}
              onJobStatusUpdated={(status) => console.log(status)}
            />
          }
        </React.Fragment>
      ))}
    </>
  )
}

const stoplightColumnOrder = [
  {label: "Manager Overall", code: ResearchCategoryCode.OOVER, objectKey: "managerOverall", settingKey: "showManagerOverall"},
  {label: "Manager People", code: ResearchCategoryCode.OPEO, objectKey: "managerPeople", settingKey: "showManagerPeople"},
  {label: "Product People", code: ResearchCategoryCode.PPEO, objectKey: "productPeople", settingKey: "showProductPeople"},
  {label: "Philosophy/ Process", code: ResearchCategoryCode.PHIL, objectKey: "philosophy", settingKey: "showPhilosophyProcess"},
  {label: "Product Dynamics", code: ResearchCategoryCode.CONST, objectKey: "dynamics", settingKey: "showProductDynamics"},
  {label: "Short Term Performance", code: ResearchCategoryCode.STPERF, objectKey: "shortTerm", settingKey: "showShortTermPerformance"},
  {label: "Long Term Performance", code: ResearchCategoryCode.PERF, objectKey: "longTerm", settingKey: "showLongTermPerformance"},
  {label: "Product Overall", code: ResearchCategoryCode.POVER, objectKey: "productOverall", settingKey: "showProductOverall"},
]

type ListExpandedWithStatus = listExpanded & {
  subGroup?: ListExpandedWithStatus[]
  vehicle?: DashboardStoplightStatusFragment['vehicle']
  manager?: DashboardStoplightStatusFragment['manager']
  product?: DashboardStoplightStatusFragment['product']
  portfolio?: ReportCommonClientPortfolioFragment
  managerOverall?: DashboardStoplightStatusDetailFragment
  managerPeople?: DashboardStoplightStatusDetailFragment
  productOverall?: DashboardStoplightStatusDetailFragment
  productPeople?: DashboardStoplightStatusDetailFragment
  longTerm?: DashboardStoplightStatusDetailFragment
  shortTerm?: DashboardStoplightStatusDetailFragment
  philosophy?: DashboardStoplightStatusDetailFragment
  dynamics?: DashboardStoplightStatusDetailFragment
}

const StoplightReportDisplay:React.FC<IndividualComponentProps<DashboardStoplightFragment>> = (props) => {
  const {component, auth, settings, selected, handleSelect, componentNumber, sectionNumber, editMode, report, setEditedDraftLayout, editedDraftLayout, setSelectedComponentId, view, hideSingleExport, clientId, portfolioId} = props
  const name = component.name || ""
  const queryVariables = { listId: settings.list?.id || 0, clientId: settings.client?.id || 0, dateType: CallanDateType.IAG_CURRENT_QUARTER }
  const [urlErrorOpen, setUrlErrorOpen] = useState(false)
  const [openItems, setOpenItems] = useState<(number | string)[]>([])
  const [dateShow, setDateShow] = useState(false)
  const [optionModalOpen, setOptionModalOpen] = useState(false)

  const { loading, data, error } = useDashboardStoplightQuery({
    fetchPolicy: "no-cache",
    variables: queryVariables
  })

  const [getStoplightUrl] = useDownloadStoplightReportLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data.download?.url){
        const downloadLink = document.createElement("a")
        downloadLink.href = data.download.url
        downloadLink.download = "Stoplight"
        downloadLink.click()
      } else {
        setUrlErrorOpen(true)
      }
    },
    onError: (error: ApolloError) => {
      setUrlErrorOpen(true)
    }
  });

  const downloadStoplight = () => {
    getStoplightUrl({variables: {args: { list: settings.list?.id || 0, client: settings.client?.id || 0, useListOrder: settings.useListOrder }}})
  }

  if(loading) return <LoadingComponent name={name} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
  if(error) return <ErrorComponent name={name} error={error.message} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>
  if(!data?.list) return <ErrorComponent name={name} error={"No data"} componentNumber={componentNumber} editMode={editMode} selected={selected} onClick={handleSelect} sectionNumber={sectionNumber} setEditedDraftLayout={setEditedDraftLayout}/>

  const groupedStatuses = groupBy(data?.statusesForList, 'vehicle.vehicle.id')
  let mappedData: ListExpandedWithStatus[]

  if(settings.useListOrder) {
    const expandedList = expandList(data.list as ListDetailFragment)

    const iterateList = (list: listExpanded[]):any => {
      return compact(list.map(item => {
        let OpinionHash:{[key:string]:DashboardStoplightStatusDetailFragment} = {}
        let statusInfo, portfolioItem
        if("vehicle" in item.item){
          statusInfo = first(groupedStatuses[item.item.vehicle.id])
        } else if ("product" in item.item){
          const separateVehicle = find(item.item.product.vehicles, (vehicle) => vehicle.vehicle.category.code === VehicleCategoryCode.s)
          statusInfo = first(groupedStatuses[separateVehicle.vehicle.id])
        } else if ("relatedVehicle" in item.item && item.item.relatedVehicle?.vehicle?.id) {
          const performanceSource = item.item?.performanceSource?.code || "NO"
          const hideRow = !!item.item ? (moment(item.item?.serviceEndDate, DATE_API_FORMAT) < moment(appDate, DATE_API_FORMAT)) || (moment(item.item?.serviceStartDate, DATE_API_FORMAT).endOf('month') >= moment(appDate, DATE_API_FORMAT).endOf('month')) : false

          if(performanceSource === "HIDE" || hideRow){
            return null
          }
          portfolioItem = item.item
          statusInfo = first(groupedStatuses[item.item.relatedVehicle.vehicle.id])
        }
        const itteratedList = iterateList(item.subGroup || [])

        if(statusInfo) {
          statusInfo.statuses?.forEach((opinion:DashboardStoplightStatusDetailFragment | null) => {
            if(opinion?.researchCategory &&
              (
                !OpinionHash[opinion.researchCategory] ||
                moment(OpinionHash[opinion.researchCategory]?.lastChangedDate).isBefore(moment(opinion.lastChangedDate
              )))){
              OpinionHash[opinion.researchCategory] = opinion
            }
          })

          return({
            ...item,
            subGroup: item.subGroup ? itteratedList : undefined,
            vehicle: statusInfo.vehicle,
            manager: statusInfo.manager,
            product: statusInfo.product,
            portfolio: portfolioItem,
            managerOverall: OpinionHash[ResearchCategoryCode.OOVER],
            managerPeople: OpinionHash[ResearchCategoryCode.OPEO],
            productOverall: OpinionHash[ResearchCategoryCode.POVER],
            productPeople: OpinionHash[ResearchCategoryCode.PPEO],
            longTerm: OpinionHash[ResearchCategoryCode.PERF],
            shortTerm: OpinionHash[ResearchCategoryCode.STPERF],
            philosophy: OpinionHash[ResearchCategoryCode.PHIL],
            dynamics: OpinionHash[ResearchCategoryCode.CONST],
          })
        } else if(item?.subGroup && itteratedList.length > 0){
          return {
            ...item,
            subGroup: itteratedList,
          }
        }
        return undefined
      }))
    }

    mappedData = iterateList(expandedList)
  } else {
    const assetClasses = sortBy(uniqBy(data?.statusesForList?.map((statusForList) => statusForList?.product?.product?.assetClass), 'id'), 'order')
    const groupedAssetClasses = groupBy(data?.statusesForList, 'product.product.assetClass.id')

    mappedData = compact(assetClasses?.map(assetClass => {
      if(!assetClass) return undefined
      const subGroup = compact(groupedAssetClasses[assetClass.id].map((item) => {
        let OpinionHash:{[key:string]:DashboardStoplightStatusDetailFragment} = {}
        if(!item) return undefined
        let statusInfo:DashboardStoplightStatusFragment | undefined = item

        if(statusInfo) {
          statusInfo.statuses?.forEach((opinion:DashboardStoplightStatusDetailFragment | null) => {
            if(opinion?.researchCategory &&
              (
                !OpinionHash[opinion.researchCategory] ||
                moment(OpinionHash[opinion.researchCategory]?.lastChangedDate).isBefore(moment(opinion.lastChangedDate
              )))){
              OpinionHash[opinion.researchCategory] = opinion
            }
          })

          const portfolioItem = (find(data.list?.items, (listItem) => listItem?.item && "relatedVehicle" in listItem.item && listItem.item.relatedVehicle?.vehicle?.id === statusInfo?.vehicle?.vehicle?.id) as any)?.item

          const performanceSource = portfolioItem?.performanceSource?.code || "NO"
          const hideRow = !!portfolioItem ? (moment(portfolioItem?.serviceEndDate, DATE_API_FORMAT) < moment(appDate, DATE_API_FORMAT)) || (moment(portfolioItem?.serviceStartDate, DATE_API_FORMAT).endOf('month') >= moment(appDate, DATE_API_FORMAT).endOf('month')) : false

          if(performanceSource === "HIDE" || hideRow){
            return null
          }

          return{
            id: statusInfo.vehicle?.vehicle?.id || "",
            name: get(portfolioItem, 'item.portfolioName') || statusInfo.vehicle?.vehicle?.vehicleName || "",
            uniqId: `Vehicle:${statusInfo.vehicle?.vehicle?.id}`,
            vehicle: statusInfo.vehicle,
            manager: statusInfo.manager,
            product: statusInfo.product,
            portfolio: portfolioItem,
            managerOverall: OpinionHash[ResearchCategoryCode.OOVER],
            managerPeople: OpinionHash[ResearchCategoryCode.OPEO],
            productOverall: OpinionHash[ResearchCategoryCode.POVER],
            productPeople: OpinionHash[ResearchCategoryCode.PPEO],
            longTerm: OpinionHash[ResearchCategoryCode.PERF],
            shortTerm: OpinionHash[ResearchCategoryCode.STPERF],
            philosophy: OpinionHash[ResearchCategoryCode.PHIL],
            dynamics: OpinionHash[ResearchCategoryCode.CONST],
          }
        }
        return null
      }))
      return {
        id: assetClass.id || "",
        name: assetClass.fullName || "",
        uniqId: `AssetClass:${assetClass.id}`,
        subGroup: sortBy(subGroup, 'vehicle.vehicle.vehicleName'),
      }
    }))
  }

const optionsModal = (
  <OptionsModal show={optionModalOpen} setShow={setOptionModalOpen} onSubmit={() => setOptionModalOpen(false)} hideCancelButton>
    <div className='d-flex ml-4'>
      <FormInput
        key={"top-quartile"}
        property={"top-quartile"}
        displayName={"Show Dates"}
        type={"checkbox"}
        subtype={"show"}
        idx={1}
        editMode={true}
        propertyVal={dateShow}
        updateValue={(value) => setDateShow(value)}
        subClasses={{wrapperClasses: "no-gutters w-100", labelClasses: "col-sm-8", inputWrapperClasses: "col-sm-4"}}
      />
    </div>
  </OptionsModal>
)

  let allOpenableItems = expandableReportList(mappedData as listReportExpanded[])
  const allExpanded = isEqual(intersection(allOpenableItems, openItems), allOpenableItems)

  let tooltipProps:ToolbarProps | undefined

  if(componentNumber === -1){
    tooltipProps = {
      'exportFunction': downloadStoplight,
      openOptionsModal: () => setOptionModalOpen(!optionModalOpen),
    }
  }

  return (
    <>
      <TemplateComponent
        name={name}
        selected={selected}
        onClick={handleSelect}
        tooltipProps={tooltipProps}
        editMode={editMode}
        sectionNumber={sectionNumber}
        report={report}
        setEditedDraftLayout={setEditedDraftLayout}
        editedDraftLayout={editedDraftLayout}
        componentNumber={componentNumber}
        componentId={component.id}
        setSelectedComponentId={setSelectedComponentId}
        view={view}
        auth={auth}
        hideExport={hideSingleExport}
        portfolioId={portfolioId}
      >
        {optionsModal}
        <Table hover className={"report-table"}>
          <thead>
            <tr className="row-border-olive-100">
              <th className="text-left">
                Portfolio
                <div className="fake-link d-inline-block ml-2" onClick={() => allExpanded ? setOpenItems([]) : setOpenItems(allOpenableItems) }>{allExpanded ? "Collapse All" : "Expand All"}</div>
              </th>
              {stoplightColumnOrder.map((column) => {
                if(!get(settings, column.settingKey)) return <React.Fragment key={column.code} />
                return(<th key={column.code}>{column.label}</th>)
              })}
            </tr>
          </thead>
          <tbody>
            {mappedData.map((listItem: ListExpandedWithStatus, idx) => {
              return(
                <StoplightRow
                  key={idx}
                  list={listItem}
                  openItems={openItems}
                  currentHierarchy={[]}
                  setOpenItems={(openItems) => setOpenItems(openItems)}
                  settings={settings}
                  dateShow={dateShow}
                  report={report}
                  view={view}
                  clientId={clientId}
                />
              )
            })}
          </tbody>
        </Table>
      </TemplateComponent>
    </>
  )
}

interface StoplightRowProps {
  list: ListExpandedWithStatus
  openItems: (number | string)[]
  currentHierarchy: (number | string)[]
  settings: DashboardStoplightFragment
  setOpenItems: (newOpenItems: (number | string)[]) => void
  dateShow: boolean
  report?: ReportsFragment
  view: ReportDisplayType
  clientId?: number
}

const StoplightRow: React.FC<StoplightRowProps> =({list, currentHierarchy, openItems, setOpenItems, settings, dateShow, report, view, clientId}) => {
  const hasSubgroup = !!list.subGroup && list.subGroup.length > 0
  const depth = currentHierarchy.length
  const [isOpen, setIsOpen] = useState<boolean>(openItems.includes(list.id))
  useEffect(() => { setIsOpen(openItems.includes(list.id)) }, [openItems, list.id])
  const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(!hasSubgroup) return
    if(isOpen){
      let removedArray = clone(openItems)
      remove(removedArray, (id) => list.id === id)
      setOpenItems(removedArray)
    } else {
      setOpenItems([...openItems, list.id])
    }
  }

  return (
    <>
      <tr className={classNames("report-tr-title", {"report-tr-subgroup": !hasSubgroup})}>
        <td className={classNames("text-left")} onClick={(e) => handleExpand(e)}>
          <div className={classNames("report-table-item", {"has-subgroup": hasSubgroup})}>
            <div style={{marginLeft: depth * 10}} className="d-flex">
              {(depth !== 0 || hasSubgroup) &&
                <div className={`report-table-caret report-table-depth-${depth} `}>
                  {hasSubgroup &&
                    <FontAwesomeIcon
                      icon={isOpen ? "caret-down" : "caret-right"}
                      size="sm"
                    />
                  }
                </div>
              }
              <div>
                <div className={classNames("report-table-title")}>
                  <FootnotableComponent
                    links={getReportLinks({component: list.portfolio, reportId: report?.id, clientId, view})}
                  >
                    {list.name}
                  </FootnotableComponent>
                </div>
              </div>
            </div>
          </div>
        </td>
        {stoplightColumnOrder.map((column) => {
          if(!get(settings, column.settingKey)) return <React.Fragment key={column.code} />
          const statusObject = get(list, column.objectKey)
          return(
            <td className='text-nowrap' key={column.code}>
              {statusObject && <FontAwesomeIcon icon="circle" className={`text-${statusColor(statusEnumToValue(statusObject?.status))}`} title={statusEnumToValue(statusObject?.status)}/>}
              {statusObject && dateShow && <div className="text-muted d-inline-block ml-1">{moment(statusObject?.lastChangedDate).format("MM/DD/YYYY")}</div>}
            </td>)
        })}

      </tr>
      {isOpen && hasSubgroup && list.subGroup?.map((listItem: ListExpandedWithStatus, idx) => {
        return(
          <StoplightRow
            key={idx}
            list={listItem}
            openItems={openItems}
            currentHierarchy={[...currentHierarchy, list.id]}
            setOpenItems={(openItems) => setOpenItems(openItems)}
            settings={settings}
            dateShow={dateShow}
            report={report}
            view={view}
            clientId={clientId}
          />
        )
      })}
    </>
  )
}

type IagReportPollerProps = {
  id: string
  gridApi?: GridApi
  onJobStatusUpdated: (status: string) => void
}

export const IagReportPoller: React.FC<IagReportPollerProps> = ({ children, id, gridApi, onJobStatusUpdated }) => {
  const [ runIagReport, {data} ] = useRunIagReportMutation({fetchPolicy: "no-cache"})

  // Run once on component mount
  useEffect(() => {
    runIagReport({
      variables: {
        args: {
          FUNDID: id,
          // DATE: data?.callanDate,
          REPORT_TYPE: "REV",
        },
      },
    })
    gridApi?.deselectAll()
  }, [])

  if(false && data?.runIagReportV2?.jobId) {
    return (
      <>
        <JobPoller
          jobId={data?.runIagReportV2?.jobId || 0}
          onJobStatusUpdated={(status, message) => onJobStatusUpdated(message)}
        />
      </>
    )
  }

  return (
    <></>
  )
}

type DownloadReportsProps = {
  query: DocumentNode
  downloadingSeries: string[]
  setDownloadingReports: (document: DocumentNode | undefined) => void
  setDownloadingSeries: (series: string[]) => void
}

export const DownloadReports: React.FC<DownloadReportsProps> = ({ query, downloadingSeries, setDownloadingReports, setDownloadingSeries }) => {
  const [getZipArchive, {error: zipError, loading: zipLoading}] = useGetZipArchiveLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if(data.getZipArchiveUrl?.url){
        downloadWithFilename(data.getZipArchiveUrl?.url, "reports.zip")
        setDownloadingReports(undefined)
        setDownloadingSeries([])
      }
    }
  })

  const {error, loading} = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      let zippedDocuments:ZipArchiveFile[] = []
      downloadingSeries.forEach((series) => {
        const allDocuments = get(data, `${series}.product.documents`)
        zippedDocuments = zippedDocuments.concat(
          allDocuments.filter(
            (document: FilesZipFragment) =>
              document.type?.code === DocumentType._5 &&
              document.subType?.code === DocumentSubType._48
          ).map((document: FilesZipFragment) => ({
              id: document.id,
              versionId: document.versionId,
            }
          ))
        )
      })
      if(zippedDocuments.length > 0) {
        getZipArchive({
          variables: {
            input: {
              name: "IAG Reports.zip",
              files: zippedDocuments,
            },
          },
        })
      } else {
        setDownloadingReports(undefined)
        setDownloadingSeries([])
      }
    }
  })

  if(loading || zipLoading) return <FontAwesomeIcon icon="spinner-third" spin />
  if(error || zipError) return <FontAwesomeIcon icon="exclamation-circle" />

  return (
    <></>
  )
}

const StoplightTableFields = (settings: DashboardStoplightFragment):ReportEditField[] => {
  const columns = stoplightColumnOrder.map((col) => ({
    property: col.settingKey,
    label: col.label,
    type: "checkbox",
    subtype: "show",
    required: true,
  }))
  return [
    {
      property: "useListOrder",
      label: "Portfolio Order",
      type: "select",
      options: [{code: "true", value: "Use List order"}, {code: "false", value: "Use Asset Class order"}],
      required: true,
    },
    {
      property: "",
      label: "Columns",
      type: "label",
    },
    ...columns
  ]
}


export const StoplightEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, reportId}) => {
  const settings = component.draftSettings as DashboardStoplightFragment
  if(!settings){
    return(
      <></>
    )
  }

  const ownedComponent = component.owner?.id === user?.person?.id
  return (
    <>
      <ReportEditHeading
        component={component}
        portfolio={portfolio}
        ownedComponent={ownedComponent}
        name={component.name || "Stoplight"}
        componentType={ComponentType.Stoplight}
        reportId={reportId}
        handleInputChange={handleInputChange}
      />
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <ReportEditInstances component={component}/>
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={ReportEditInfoFields(handleInputChange, {hideDate: true})}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      />
      <SimpleReportEdit
        name={"table"}
        retractable={true}
        fields={StoplightTableFields(settings)}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
    </>
  )
}


export default Stoplight