import { History } from 'history'
import React, { useEffect, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { clone, compact, get, intersection, isEqual, remove, uniq } from 'lodash'
import { ComponentApprovalCode, MeFragment } from '../../../__generated__/graphql'
import Auth from '../../../Auth/Auth'
import { expandableReportList, expandableReportListSearch, getExpandableReportLocation, listReportExpanded } from '../../../helpers/report'
import { ReportDisplayType } from './ReportComponent'

interface ReportSidebarProps {
  clientId?: number
  reportId?: number
  history?: History
  auth?: Auth
  portfolioId?: number
  user?: MeFragment
  list: listReportExpanded[]
  view: ReportDisplayType
  externalLink?: (selected: listReportExpanded) => void
  startOpen?: boolean
  search?: string
  holderClass?: string
}

const ReportSidebar: React.FC<ReportSidebarProps> = ({ auth, portfolioId, user, history, clientId, reportId, list, view, externalLink, startOpen, search, holderClass}) => {
  const sidebarRef = useRef<HTMLDivElement>(null)
  const pathToCurrent = getExpandableReportLocation(list, portfolioId)
  const [openItems, setOpenItems] = useState<number[]>(pathToCurrent)
  const [expanded, setExpanded] = useState(!!startOpen)
  let allOpenableItems = expandableReportList(list)
  const allExpanded = isEqual(intersection(allOpenableItems, openItems), allOpenableItems)
  const isMounted = useRef(false);
  const [shownItems, setShownItems] = useState<number[] | undefined>(undefined)

  useEffect(() => {
    if(!!search){
      setShownItems(expandableReportListSearch(list, search))
      setOpenItems(expandableReportListSearch(list, search))
    } else {
      setShownItems(undefined)
      setOpenItems([])
    }
  }, [search])

  useEffect(() => {
    if(portfolioId){
      setOpenItems(compact(uniq([...openItems,...getExpandableReportLocation(list, portfolioId)])))
      if (isMounted.current) { // Used to stop running on first render
        setExpanded(true);
      } else {
        isMounted.current = true;
      }
    }
  }, [portfolioId])

  return (
    <div
      ref={sidebarRef}
      className={classNames("report-sidebar", 'overflow-y-auto', 'overflow-x-hidden', holderClass, {"report-sidebar-expanded": expanded, "report-sidebar-collapsed cursor-pointer": !expanded})}
      onClick={() => !expanded && setExpanded(!expanded)}
    >
      {!expanded &&
        <div>
          <FontAwesomeIcon
            icon="bars"
          />
          <br />
          Open
        </div>
      }
      {expanded &&
        <div>
          <div className='sticky-top background-white'>
            <div className="fake-link report-sidebar-expand-link" onClick={() => allExpanded ? setOpenItems([]) : setOpenItems(allOpenableItems) }>
              {allExpanded ? "Collapse All" : "Expand All"}
            </div>
            <div className="report-sidebar-item cursor-pointer" onClick={() => setExpanded(!expanded)}>
              — <span className="text-blue-100">Close</span>
            </div>
          </div>
          {list.map((listItem: listReportExpanded, idx) => {
            return(
              <SidebarItem
                key={idx}
                clientId={clientId}
                reportId={reportId}
                history={history}
                list={listItem}
                selectedId={portfolioId}
                openItems={openItems}
                shownItems={shownItems}
                currentHierarchy={[]}
                setOpenItems={setOpenItems}
                view={view}
                externalLink={externalLink}
              />
            )
          })}
        </div>
      }
    </div>
  )
}

interface SidebarItemProps {
  clientId?: number
  reportId?: number
  history?: History
  list: listReportExpanded
  selectedId?: number
  openItems: number[]
  shownItems?: number[]
  currentHierarchy: number[]
  setOpenItems: (newOpenItems: number[]) => void
  view: ReportDisplayType
  externalLink?: (selected: listReportExpanded) => void
}

export const SidebarItem: React.FC<SidebarItemProps> =({clientId, reportId, list, currentHierarchy, selectedId, openItems, shownItems, history, setOpenItems, view, externalLink}) => {
  const selected = selectedId === list.id
  const hasSubgroup = !!list.subGroup && list.subGroup.length > 0
  const depth = currentHierarchy.length
  const isOpen = openItems.includes(list.id)
  let approvalStatus = "no-components"
  const handleExpand = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    if(isOpen){
      let removedArray = clone(openItems)
      remove(removedArray, (id) => list.id === id)
      setOpenItems(removedArray)
    } else {
      setOpenItems([...openItems, list.id])
    }
  }
  const portfolio = list.portfolio
  if(get(list, `approvalCount[${ComponentApprovalCode._1}]`, 0) > 0){
    approvalStatus = "needs-review"
  } else if(get(list, `approvalCount[${ComponentApprovalCode._2}]`, 0) > 0){
    approvalStatus = "reviewed"
  } else if(get(list, `approvalCount[${ComponentApprovalCode._3}]`, 0) > 0){
    approvalStatus = "approved"
  }
  if(view !== ReportDisplayType.Draft) {
    // Only show approval on Draft view
    approvalStatus = selected ? "approved" : ""
  }
  if(shownItems && !shownItems.includes(list.id)){
    return <></>
  }
  return (
    <>
      <div
        className={classNames(
          `report-sidebar-item report-sidebar-depth-${depth}`,
          {
            selected: selected,
            "has-subgroup": hasSubgroup,
            "has-components": portfolio?.hasComponents || (view === ReportDisplayType.External && !list.unused),
          },
          approvalStatus
        )}
        onClick={() =>{
          if(view === ReportDisplayType.Draft){
            history?.push(`/reports/${reportId}/${list.id}`)
          } else if (view === ReportDisplayType.Live){
            history?.push(`/clients/${clientId}/${reportId}/report/${list.id}`)
          } else if (view === ReportDisplayType.External && externalLink){
            externalLink(list)
          }
        }}
      >
        <div
          style={{ marginLeft: depth * 15 }}
          className="d-flex align-items-center w-100"
        >
          <div
            onClick={(e) => handleExpand(e)}
            className={`report-sidebar-caret`}
          >
            {hasSubgroup && (
              <FontAwesomeIcon
                icon={isOpen ? "caret-down" : "caret-right"}
                size="sm"
              />
            )}
          </div>
          <div className="w-100" style={{ paddingRight: depth * 15 }}>
            {/* {hasSubgroup &&
              <div className="label-blue-70 mr-2"/>
            }
            {!hasSubgroup &&
              <div className="dot-blue-70 mr-2"/>
            } */}
            <div className="report-sidebar-title">{list.name}</div>
            {/* <div className="report-sidebar-subtitle">
              {list.subTitle}
            </div> */}
          </div>
        </div>
      </div>
      {isOpen &&
        hasSubgroup &&
        list.subGroup?.map((listItem: listReportExpanded, idx) => {
          return (
            <SidebarItem
              key={idx}
              clientId={clientId}
              reportId={reportId}
              history={history}
              list={listItem}
              selectedId={selectedId}
              openItems={openItems}
              shownItems={shownItems}
              currentHierarchy={[...currentHierarchy, list.id]}
              setOpenItems={setOpenItems}
              view={view}
              externalLink={externalLink}
            />
          )
        })}
    </>
  )
}


export default ReportSidebar