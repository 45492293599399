import { CellClassParams, ColDef, GetRowIdParams, GridApi, GridColumnsChangedEvent, GridReadyEvent, IRowDragItem, ITooltipParams, RowDragEndEvent, ValueGetterParams } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cloneDeep, compact, filter, findIndex, get, map, remove, set, union, xor } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap'

import { draggingStatus, ListHeaderTracker, listMembersMapping, nicelyOrderList, typeIdMapping, UpdateListItemsProps } from '../../helpers/list'
import { FileFullDetailsFragment, ListDetailExcludeListFragment, ListDetailFragment, ListDetailItemsFragment, ListMemberIdType, ListType } from '../../__generated__/graphql'



const memberTypeMapping =  {
  'fundid': "Vehicle",
  'portfolio_num': "Portfolio",
  'product_id': "Product",
  'org_id': "Org",
  'indexid': "Index",
  'groupid': "Group",
  'fund_num': "Plan",
  'version_id': "Glide Path",
  'list_group': "List Group",
  'list_header': "Header",
  'page': "Page",
}

const orgTypeMapping = {
  'Manager': "Manager",
  'Client': "Client",
  'Index': "Index",
  'Bank': "Custodian",
  'RecordKeeper': "Record Keeper",
  'Broker': "Broker",
  'OtherFirm': "Org",
  // temp fix for typescript error
  'IndexProvider': "IndexProvider",
}

type ListDetailTableProps = {
  list: ListDetailFragment
  setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
  setGridApi: (api:GridApi) => void
  gridApi: GridApi | undefined
  editMode: boolean
  excludeListInput: UpdateListItemsProps
  setExcludeListInput: React.Dispatch<React.SetStateAction<UpdateListItemsProps>>
  mainListInput: UpdateListItemsProps
  setMainListInput: React.Dispatch<React.SetStateAction<UpdateListItemsProps>>
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  setDragging: React.Dispatch<React.SetStateAction<draggingStatus>>
  setListHeaders: React.Dispatch<React.SetStateAction<ListHeaderTracker[]>>
}

type MapItemsObject = {
  order: string;
  memberType: string;
  baseMemberType: ListMemberIdType | undefined;
  source: string[] | undefined;
  name: string;
  id: number | string;
}

export type documentTableData = {
  clientId: number
  reportId: number
  associations: string[]
} & FileFullDetailsFragment

const listDetailsTableColumnDef  = (editMode: boolean, gridApi: GridApi | undefined) => (
  [
    {
      field: "order",
      headerName: "Order",
      sortable: true,
      sort: "asc",
      width: 100,
      comparator: (valueA: string, valueB: string) => {
        if(valueA === valueB) return 0
        if(valueA === "" || valueB === "Exclude") return -1
        if(valueB === "" || valueA === "Exclude") return 1
        const splitA = valueA?.split('.')
        const splitB = valueB?.split('.')
        let returnedComparator = 0
        if(splitA && splitB){
          splitA.some((orderA, idx) => {
            const orderB = splitB[idx]
            if(orderA !== orderB){
              returnedComparator = (parseInt(orderA) || 0) > (parseInt(orderB) || 0) ? 1 : -1
              return true
            }
            return false
          })
        }
        return returnedComparator
      },
      checkboxSelection: editMode,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      rowDrag: editMode,
      // rowSelection: 'multiple',
      rowDragText: (params: IRowDragItem) => {
        const count = union([params.rowNode?.data], gridApi?.getSelectedRows()).length
        return `${count} Item${count > 1 ? "s" : ""}`
      }
    },
    {
      field: "name",
      headerName: "Member",
      sortable: true,
      width: 500,
    },
    {
      field: "id",
      headerName: "ID",
      sortable: true,
      width: 120,
    },
    {
      field: "memberType",
      headerName: "Member Type",
      sortable: true,
      width: 200,
      getQuickFilterText: () => "",
    },
    {
      field: "source",
      headerName: "Source",
      sortable: true,
      width: 200,
      getQuickFilterText: () => "",
      valueGetter: (params: ValueGetterParams) => {
        if(params.data && params.data.source) {
          if(params.data.source.length > 1){
            return "Multiple"
          }
          return params.data.source
        }
        return "Current List"
      },
      tooltipValueGetter: (params: ITooltipParams) => {
        if(params.data && params.data.source && params.data.source.length > 1){
          return params.data.source
        }
        return ""
      },
      cellClassRules: {'font-italic': (params:CellClassParams) => { return !params?.data?.source }
      },
    },
]) as ColDef[]

const ListDetailTable: React.FC<ListDetailTableProps> = ({ list, setEditedList, setGridApi, editMode, gridApi, excludeListInput, setExcludeListInput, setMainListInput, setSelectedItems, setDragging, setListHeaders}) => {
  const [search, setSearch] = useState("")

  const heading = (
    <>
      <div className="list-table-header d-flex align-items-center justify-content-between background-white">
        <h3 className="pl-3 mb-0 font-weight-500">List Members</h3>
        <Form className="">
          <FormGroup row className="relative mr-1 my-0 ml-0">
            <Input
              type="text"
              placeholder="Find Member"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="wide-search"
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        <div></div>
      </div>
    </>
  )

  const moveItems = (moveableRowsIn:MapItemsObject[]) => {
    let moveableRows = cloneDeep(moveableRowsIn)
    let movedItems:string[] = []
    if(moveableRows.length > 0){
      setEditedList((editedList) => {
        if(!editedList){
          return editedList
        }
        // Update all remaining
        if(editedList.items){
          let updatedItems = map(editedList.items, (item) => {
            const itemId = get(item, `item.${typeIdMapping[item.type as ListMemberIdType]}`, "")
            const foundIndex = moveableRows.findIndex((row, idx) => {
              if(!(row.order === "" || row.order?.charAt(0) === ".")){
                return false
              }
              return item.type === row.baseMemberType && itemId === row.id
            })
            if(itemId && foundIndex !== -1){
              movedItems.push(`${item.type}:${itemId}`)
              moveableRows.splice(foundIndex, 1)
              return {
                ...item,
                order: 0 - (moveableRows.length - foundIndex + 1)/(moveableRows.length + 2),
                group: 1,
              }
            }
            return item
          })
          let orderedUpdatedItems = nicelyOrderList(updatedItems)
          return {...editedList, items: orderedUpdatedItems}
        }
      })
      // TODO: this line causes rerender, Fix this
      console.log("moveIn", {cnt: moveableRows.length, moveableRows, movedItems})
      setSelectedItems(movedItems)
    }
  }

  const handleDragStop = (params: RowDragEndEvent) => {
    // TODO: Fix this, it's not working
    let items = union([params.node.data], params.api?.getSelectedRows())
    moveItems(items)
    params.api?.deselectAll()
  }

  const addToOrder = () => {
    if(gridApi){
      moveItems(gridApi?.getSelectedRows())
      gridApi.deselectAll()
    }
  }

  const dropZone = (api:GridApi) => {
    var tileContainer = document.querySelector('.list-sidebar')
    var dz = {
      getContainer: () => {
        return tileContainer as HTMLElement
      },
      onDragStop: (params: RowDragEndEvent) => handleDragStop(params)
    }
    return dz
  }

  useEffect(() => {
    if(gridApi){
      const dz = dropZone(gridApi)
      gridApi.removeRowDropZone(dz)
      gridApi.addRowDropZone(dz)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  useEffect(() => {
    if(!editMode && gridApi){
      gridApi?.deselectAll()
    }
  }, [editMode])

  const onReady = (event: GridReadyEvent) => {
    const api = event.api
    setGridApi(api)
    api.addRowDropZone(dropZone(api));
  }
  const onGridColumnsChanged = (event: GridColumnsChangedEvent) => {
    // The ids swap when edit mode is toggled
    event.columnApi.applyColumnState({state: [
      {colId: 'order', sort:"asc"},
      {colId: 'order_1', sort:"asc"},
    ]})
  }
  const onRemoveItem = () => {
    // Check origin of item, if from current list then remove from main list
    // if from another list then add to exclude
    if(list.excludeList && gridApi){
      const rows = gridApi?.getSelectedRows()
      gridApi.deselectAll()
      setEditedList((editedList)=> {
        if(!editedList){
          return editedList
        }
        if(editedList.items && rows.length > 0){
          let updatedItems = cloneDeep(editedList.items)
          rows.forEach((row, idx) => {
            const baseType = row.baseMemberType as ListMemberIdType
            const mainIndex = findIndex(updatedItems, (item) => {
              // console.log(item.type === baseType, get(item, `item.${typeIdMapping[baseType]}`)?.toString() === row.id.toString())
              return item.type === baseType && get(item, `item.${typeIdMapping[baseType]}`, "")?.toString() === row.id.toString()
            })
            // console.log(mainIndex, row)
            if(mainIndex !== -1){
              const mainItem = updatedItems.splice(mainIndex, 1)[0]
              const listMemberMapping = get(listMembersMapping, mainItem.type || "", "")
              const mainItemId = get(mainItem, `item.${typeIdMapping[baseType]}`, "")
              if(!mainItem.fromList || mainItem.fromList.length === 0 || mainItem.fromList.map((fromList) => fromList?.id).includes(editedList.id)){
                // just remove it from the list
                setMainListInput((mainListInput) => {
                  let updatedMainList = cloneDeep(mainListInput)
                  if(!listMemberMapping){
                    // header, don't update list member.
                    return updatedMainList
                  }
                  if(get(updatedMainList.add, listMemberMapping, [] as string[]).includes(mainItemId)){
                    let addList: string[] = get(updatedMainList.add, listMemberMapping, [])
                    addList = xor(addList, [mainItemId])
                    set(updatedMainList.add, listMemberMapping, addList)
                  } else {
                    let removeList: string[] = get(updatedMainList.remove, listMemberMapping, [])
                    removeList = union(removeList, [mainItemId])
                    set(updatedMainList.remove, listMemberMapping, removeList)
                  }
                  return updatedMainList
                })
                // headers have additional logic
                if(mainItem.type === "list_header"){
                  setListHeaders((listHeaders) => {
                    let cloneListHeaders = cloneDeep(listHeaders)
                    const matchingHeader = listHeaders.find((header) => (header.id).toString() === mainItemId)
                    if(matchingHeader){
                      remove(cloneListHeaders, (header) => header.id === matchingHeader.id)
                    }
                    if(matchingHeader?.status === "created"){
                      return cloneListHeaders
                    } else {
                      return [...cloneListHeaders, {id: mainItemId as unknown as number, status: "deleted", value: list.name || ""}]
                    }
                  })
                }
              }
            }
          })
          return {...editedList, items: nicelyOrderList(updatedItems)}
        }
        return editedList
      })
    }
  }
  const onToggleItem = () => {
    if(list.excludeList && gridApi){
      const rows = gridApi?.getSelectedRows()
      gridApi.deselectAll()
      setEditedList((editedList)=> {
        if(!editedList){
          return editedList
        }
        let primaryExcludeListIdex = editedList.excludeList?.findIndex((el) => el.id === editedList.primaryExcludeList?.id)
        let primaryExcludeList = ((primaryExcludeListIdex || primaryExcludeListIdex === 0) && editedList.excludeList) ? editedList.excludeList[primaryExcludeListIdex] : undefined
        if(editedList.items && rows.length > 0){
          let updatedItems = cloneDeep(editedList.items)
          let excludeItems = cloneDeep(primaryExcludeList?.items)
          rows.forEach((row, idx) => {
            const baseType = row.baseMemberType as ListMemberIdType
            const mainIndex = findIndex(updatedItems, (item) => {
              return item.type === baseType && get(item, `item.${typeIdMapping[baseType]}`, "")?.toString() === row.id.toString()
            })
            const excludeIndex = findIndex(excludeItems, (item) => {
              return row.order === "Exclude" && item.type === baseType && get(item, `item.${typeIdMapping[baseType]}`, "")?.toString() === row.id.toString()
            })
            if(mainIndex === -1 && excludeIndex !== -1 && excludeItems){
              // Remove from exclude and re-add to main
              const excludeItem = excludeItems.splice(excludeIndex, 1)[0]
              const listMemberMapping = get(listMembersMapping, excludeItem.type || "", "")
              const excludeItemId = get(excludeItem, `item.${typeIdMapping[baseType]}`, "")
              updatedItems.push({...excludeItem, group: undefined, order: undefined})
              setExcludeListInput((excludeListInput) => {
                let updatedExcludeList = cloneDeep(excludeListInput)
                if(get(updatedExcludeList.add, listMemberMapping, [] as string[]).includes(excludeItemId)){
                  let addList = get(updatedExcludeList.add, listMemberMapping, [] as string[])
                  addList = xor(addList, [excludeItemId])
                  set(updatedExcludeList.add, listMemberMapping, addList)
                } else {
                  let removeList = get(updatedExcludeList.remove, listMemberMapping, [] as string[])
                  removeList = union(removeList, [excludeItemId])
                  set(updatedExcludeList.remove, listMemberMapping, removeList)
                }
                return updatedExcludeList
              })
            } else if (mainIndex !== -1 && excludeIndex === -1){
              // Remove from main and add to exclude
              const mainItem = updatedItems.splice(mainIndex, 1)[0]
              const listMemberMapping = get(listMembersMapping, mainItem.type || "", "")
              const mainItemId = get(mainItem, `item.${typeIdMapping[baseType]}`, "")
              if (excludeItems) excludeItems.push({...mainItem, group: undefined, order: undefined})
              setExcludeListInput((excludeListInput) => {
                let updatedExcludeList = cloneDeep(excludeListInput)
                if(get(updatedExcludeList.remove, listMemberMapping, [] as string[]).includes(mainItemId)){
                  let removeList = get(updatedExcludeList.remove, listMemberMapping, [] as string[])
                  removeList = xor(removeList, [mainItemId])
                  set(updatedExcludeList.remove, listMemberMapping, removeList)
                } else {
                  let addList = get(updatedExcludeList.add, listMemberMapping, [] as string[])
                  addList = union(addList, [mainItemId])
                  set(updatedExcludeList.add, listMemberMapping, addList)
                }
                return updatedExcludeList
              })
            }
          })
          if(excludeItems && (primaryExcludeListIdex || primaryExcludeListIdex === 0)){
            let excludeList = cloneDeep(editedList.excludeList) || []
            excludeList[primaryExcludeListIdex] = {...excludeList[primaryExcludeListIdex],  items: nicelyOrderList(excludeItems)}
            return {...editedList, items: nicelyOrderList(updatedItems), excludeList: excludeList}
          } else {
            return {...editedList, items: nicelyOrderList(updatedItems)}
          }
        }
        return editedList
      })
    }
  }
  let listGroups = filter(list.items, (item) => item.item?.__typename === "ListGroup").reduce((groups:{[key:number]: {group: number | undefined, order: number | undefined}}, listGroup) => {
    if(listGroup.item?.__typename === "ListGroup"){
      let newGroups = cloneDeep(groups)
      newGroups[listGroup.item.id] = {group: listGroup.group || undefined, order: listGroup.order || undefined}
      return newGroups
    }
    return groups
  }, {})

  const recursivelyFetchGroup = (group: number, currentHierarchy: number[]):string => {
    const fetchedGroup = listGroups[group]
    if(fetchedGroup?.group){
      const fetchedOrder = fetchedGroup.order?.toString() || ""
      if(fetchedGroup.group === 1 || currentHierarchy?.includes(fetchedGroup.group)){
        return fetchedOrder
      } else {
        return recursivelyFetchGroup(fetchedGroup.group, [...currentHierarchy, fetchedGroup.group]) + `.${fetchedOrder}`
      }
    }
    return ""
  }

  const mapItems = (array:MapItemsObject[], item:ListDetailItemsFragment, excludeList?: ListDetailExcludeListFragment) => {
    if(!item.item){
      return array
    }
    let order = item.order?.toString() || ""
    if(item.group && item.group !== 1){
      order = `${recursivelyFetchGroup(item.group, [])}.${order}`
    }
    let baseParams:MapItemsObject = {
      name: "",
      id: 0,
      order: order,
      memberType: !!item.type ? memberTypeMapping[item.type] : "Unknown Type",
      baseMemberType: item.type || undefined,
      source: item.fromList && item.fromList.length > 0 ? compact(item.fromList?.map((fromList) => fromList?.name)) : undefined,
    }
    if(!!excludeList){
      baseParams.order = "Exclude"
      baseParams.source = baseParams.source || compact([excludeList.name])
    }
    if("portfolioName" in item.item){
      baseParams.name = item.item.portfolioName || ""
      baseParams.id = item.item.id
    } else if ("orgName" in item.item){
      baseParams.name = item.item.orgName || ""
      baseParams.id = item.item.id
      baseParams.memberType = orgTypeMapping[item.item.__typename]
    } else if ("planName" in item.item){
      baseParams.name = item.item.planName || ""
      baseParams.id = item.item.id
    } else if ("product" in item.item){
      baseParams.name = item.item.product?.productName || ""
      baseParams.id = item.item.product?.id || 0
    } else if ("glidePathName" in item.item){
      baseParams.name = item.item.glidePathName || ""
      baseParams.id = item.item.id
    } else if ("vehicle" in item.item){
      baseParams.name = item.item.vehicle?.vehicleName || ""
      baseParams.id = item.item.vehicle?.id || ""
    } else if ("text" in item.item){
      baseParams.name = item.item.text || ""
      baseParams.id = item.item.id
    }
    const duplicateId = findIndex(array, (duplicateItem) => duplicateItem.id === baseParams.id && duplicateItem.baseMemberType === baseParams.baseMemberType && ((duplicateItem.order === "Exclude" && baseParams.order === "Exclude") || (duplicateItem.order !== "Exclude" && baseParams.order !== "Exclude")))
    if(duplicateId !== -1){
      let newArray = cloneDeep(array)
      let oldEntry = newArray[duplicateId]
      oldEntry.source = compact([...(oldEntry.source || []), ...(baseParams.source || [])])
      oldEntry.order = oldEntry.order === "" ? baseParams.order : oldEntry.order
      newArray[duplicateId] = oldEntry
      return newArray
    }

    if(baseParams.id !== 0 && baseParams.name !== ""){
      return [...array, baseParams]
    } else {
      return array
    }
  }
  let tableData:MapItemsObject[] = []
  tableData = list.items?.reduce((array, item) => mapItems(array, item), tableData) || []
  list.excludeList?.forEach((list) => tableData = list.items?.reduce((array, item) => mapItems(array, item, list), tableData) || [])

  const rowClassRules = {
    "highlight-row": (params:any) => params.data.order === "" || params.data.order?.charAt(0) === ".",
    "gray-out-row": (params:any) => params.data.order === "Exclude",
  }

  const columnDef = useMemo(() => {
    return listDetailsTableColumnDef(editMode, gridApi)
  }, [editMode, gridApi])

  useEffect(() => {
    if(gridApi){
      // force refresh cells to fix missing drag handle
      gridApi.refreshCells()
    }
  }, [editMode, gridApi])

  const table = <div className={"pane pane-table p-0 mb-0 d-flex flex-grow-1"}>
      <Row className="flex-grow-1">
        <Col className="detail-table-container ag-grid ag-grid-view ag-theme-balham">
          <AgGridReact
          quickFilterText={search}
          key="unchanged"
          defaultColDef={{
            minWidth: 50,
            resizable: true,
            // sortable: true,
            filter: 'agSetColumnFilter',
            menuTabs: ['filterMenuTab'],
            headerComponentParams: {
              menuIcon: "fa-bars",
            }
          }}
          // fix double icons using headerTemplate.
          icons={{
            sortAscending: renderToString(<FontAwesomeIcon icon={"arrow-down"} className="ml-1"/>),
            sortDescending: renderToString(<FontAwesomeIcon icon={"arrow-up"} className="ml-1"/>),
            filter: renderToString(<FontAwesomeIcon icon="filter"/>),
            tooltip: renderToString(<FontAwesomeIcon icon="question-circle" className="mx-1"/>),
          }}
          rowData={tableData}
          getRowId={(params: GetRowIdParams) => `${get(params.data, "baseMemberType")}:${get(params.data, "id" )}:${get(params.data, "source")}`}
          rowSelection="multiple"
          rowClassRules={rowClassRules}
          enableCellTextSelection={true}
          suppressFocusAfterRefresh={true}
          onGridReady={onReady}
          onGridColumnsChanged={onGridColumnsChanged}
          onDragStarted={() => setDragging(draggingStatus.Dragging)}
          onDragStopped={() => setDragging(draggingStatus.Initial)}
          columnDefs={columnDef}
          // suppressScrollOnNewData={true}
          // frameworkComponents={{aumTooltip: AumTooltip}}
        >
        </AgGridReact>
      </Col>
    </Row>
  </div>

  return (
    <div className="flex-grow list-table-container mb-2 mr-2 d-flex flex-direction-column">
      {heading}
      {editMode &&
        <div className="list-table-edit-bar d-flex align-items-center pl-2">
          <div className="border-right">
            <Button color="secondary" className="mr-1" onClick={() => addToOrder()}>Add to Order</Button>
          </div>
          <div>
            {list.type === ListType.Static &&
              <Button color="secondary" className="ml-1" onClick={() => onRemoveItem()}>Delete</Button>
            }
            {list.type === ListType.Dynamic &&
              <Button color="secondary" className="ml-1" onClick={() => onToggleItem()}>Exclude/Include</Button>
            }
          </div>
        </div>
      }
      {table}
    </div>
  )
}

export default ListDetailTable
