import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { cloneDeep, find, findIndex, get, intersection, isEqual, remove, set, sortBy, union, uniq, xor } from 'lodash'
import React, { useState } from 'react'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Button, Col, DropdownToggle, Label, ListGroup, ListGroupItem, Row } from 'reactstrap'
import Auth from '../../Auth/Auth'
import { ListDetailFragment, ListDetailIncludeListFragment, ListDetailItemsFragment, ListFilters, ListMemberIdType, ListSearchFragment, ListSearchQuery, ListType, Person, SearchTypes, useGetAllAssetClassesQuery, useListSearchQuery } from '../../__generated__/graphql'
import { FormInputField } from '../../helpers/constant'

import { autocompleteMapping, autocompleteToItem, listMembersMapping, nicelyOrderList, typeIdMapping, UpdateListItemsProps } from '../../helpers/list'
import SearchAddStatic from '../Search/SearchAddStatic'
import { AutocompleteItemTypes } from '../Search/SearchAutocomplete'
import { FormInput } from '../ui/Forms/FormInput'
import { DebouncedSearchBar } from '../ui/Forms/SearchInput'
import { GetLookupDataToOptions } from '../ui/LookupOptions'
import EmployeePicker from '../ui/Pickers/EmployeePicker'
import PlaceHolder from '../ui/PlaceHolder'
import { ListQuickView } from './ListQuickView'



interface ListDetailAddItemProps {
  editMode: boolean
  list: ListDetailFragment
  setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
  selectedItems: string[]
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  mainListInput: UpdateListItemsProps
  setMainListInput: React.Dispatch<React.SetStateAction<UpdateListItemsProps>>
  auth: Auth
}

const ListDetailAddItem: React.FC<ListDetailAddItemProps> = ({ list, editMode, setEditedList, selectedItems, setSelectedItems, mainListInput, setMainListInput, auth }) => {
  const [expanded, setExpanded] = useState(true)

  let heading = "Add Items"
  if(list?.type === ListType.Dynamic) heading = "Saved Search"
  if(list?.type === ListType.Composite) heading = "Manage Linked Lists"

  return (
    <div className={classNames("list-table-container list-sidebar mb-2", {"list-sidebar-expanded": expanded, "list-sidebar-collapsed": !expanded})} >
      {!expanded &&
        <div className="list-table-header d-flex align-items-center justify-content-between background-white cursor-pointer" onClick={() => setExpanded(!expanded)}>
          <h4 className="pl-3 mb-0">
            {heading}
          </h4>
          <div className="pr-3">
            <FontAwesomeIcon
              icon="plus"
            />
          </div>
        </div>
      }
      {expanded &&
        <>
          <div className="list-table-header d-flex align-items-center justify-content-between background-white cursor-pointer" onClick={() => setExpanded(!expanded)}>
            <h3 className="pl-3 mb-0 font-weight-500">{heading}</h3>
            <div>
            </div>
            <div className="pr-3">
              <FontAwesomeIcon
                icon="minus"
              />
            </div>
          </div>
          <div className={classNames("position-relative d-flex flex-column h-100 ")}>
            {list.type === ListType.Static &&
              <ListAddStatic
                list={list}
                setEditedList={setEditedList}
                setSelectedItems={setSelectedItems}
                setMainListInput={setMainListInput}
                auth={auth}
              />
            }
            {list.type === ListType.Dynamic &&
              <ListAddDynamic
                list={list}
                setEditedList={setEditedList}
              />
            }
            {list.type === ListType.Composite &&
              <ListAddComposite
                list={list}
                setEditedList={setEditedList}
              />
            }
          </div>
        </>
      }
    </div>
  )
}

interface ListAddStaticProps {
  list: ListDetailFragment
  setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>
  setMainListInput: React.Dispatch<React.SetStateAction<UpdateListItemsProps>>
  auth: Auth
}

const ListAddStatic: React.FC<ListAddStaticProps> = ({ list, setEditedList, setSelectedItems, setMainListInput, auth }) => {
  const handleAdd = (addItem:AutocompleteItemTypes, linkUrl: string) => {
    setEditedList((editedList)=> {
      if(!editedList){
        return editedList
      }
      if(editedList.items){
        let updatedItems = cloneDeep(editedList.items)
        const baseType = autocompleteMapping[addItem.__typename]
        const newItem = {
          __typename: "ListMember",
          item: autocompleteToItem(addItem),
          type: baseType,
          order: null,
          group: null,
        } as ListDetailItemsFragment
        const baseId = get(newItem, `item.${typeIdMapping[baseType as ListMemberIdType]}`, "")

        const mainIndex = findIndex(updatedItems, (item) => {
          return item.type === baseType && get(item, `item.${typeIdMapping[baseType]}`, "")?.toString() === baseId.toString()
        })
        if(mainIndex === -1){
          // Remove from exclude and re-add to main
          const listMemberMapping = get(listMembersMapping, baseType)

          updatedItems.push(newItem)
          setMainListInput((mainListInput) => {
            let updatedMainList = cloneDeep(mainListInput)
            if(!get(updatedMainList.add, listMemberMapping, []).includes(baseId)){
              let addList = get(updatedMainList.add, listMemberMapping, [])
              addList = xor(addList, [baseId])
              set(updatedMainList.add, listMemberMapping, addList)
            }
            return updatedMainList
          })
        } else {
          return editedList
        }
        return {...editedList, items: nicelyOrderList(updatedItems)}
      }
      return editedList
    })
  }

  const includedRows = list.items?.map((item) => {
    const baseType = item.type as ListMemberIdType
    return item.item?.__typename + ":" + get(item, `item.${typeIdMapping[baseType]}`)
  }) || []

  // filter out group & index for now.
  let searchedTypes = [ 
    'Portfolios',
    'Clients',
    'Managers',
    'Custodians',
    'Record Keepers',
    'Plan',
    'Products',
    'Vehicles',
    'Target Date',
  ]
  return <SearchAddStatic
    handleAdd={handleAdd}
    includedRows={includedRows}
    searchedTypes={searchedTypes}
    auth={auth}
  />
}

interface ListAddDynamicProps {
  list: ListDetailFragment
  setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
  assetClassesOptions?: { code: string; value: string; }[]
  assetClassesLoading?: boolean
}

const getPersonName = (item: Person) => {
  if ("firstName" in item && "lastName" in item){
    return `${item.firstName} ${item.lastName}`
  }
  return ""
}

const ListAddDynamic: React.FC<ListAddDynamicProps> = ({ list, setEditedList }) => {
  let [assetClassesOptions, setAssetClassesOptions] = useState<{ code: string; value: string; }[]>()
  let [assetClassesLoading, setAssetClassesLoading] = useState(true)
  useGetAllAssetClassesQuery({
    skip: intersection([ListMemberIdType.portfolio_num, ListMemberIdType.product_id], list?.itemIdTypes).length === 0,
    onCompleted: (data) => {
      if(data){
        setAssetClassesOptions(
          data.assetClasses.map((ac) => ({value: ac.value || "", code: ac.code?.toString() || ""}))
        )
      }
      setAssetClassesLoading(false)
    }
  })

  return(
    <div className="">
      {list?.itemIdTypes?.includes(ListMemberIdType.fund_num) &&
        <AddDynamicPlan
          list={list}
          setEditedList={setEditedList}
        />
      }
      {list?.itemIdTypes?.includes(ListMemberIdType.fundid) &&
        <AddDynamicVehicle
          list={list}
          setEditedList={setEditedList}
        />
      }
      {list?.itemIdTypes?.includes(ListMemberIdType.org_id) &&
        <AddDynamicOrg
          list={list}
          setEditedList={setEditedList}
        />
      }
      {list?.itemIdTypes?.includes(ListMemberIdType.portfolio_num) &&
        <AddDynamicPortfolio
          list={list}
          setEditedList={setEditedList}
          assetClassesOptions={assetClassesOptions}
          assetClassesLoading={assetClassesLoading}
        />
      }
      {list?.itemIdTypes?.includes(ListMemberIdType.product_id) &&
        <AddDynamicProduct
          list={list}
          setEditedList={setEditedList}
          assetClassesOptions={assetClassesOptions}
          assetClassesLoading={assetClassesLoading}
        />
      }
    </div>
  )
}

const PlanInput: FormInputField[] = [
  {
    property: "dynamicFilters.plan.client",
    label: "Client",
    type: "search",
    searchTypes: [SearchTypes.Client, SearchTypes.Manager],
  },
  {
    property: "dynamicFilters.plan.fundType",
    label: "Fund Type",
    type: "select",
    subtype: "multiple",
    optionSource: "FundTypeCode",
  },
  {
    property: "dynamicFilters.plan.owner",
    label: "Owner",
    type: "search",
    searchTypes: [SearchTypes.People],
  },
  {
    property: "dynamicFilters.plan.accountType",
    label: "Account Type",
    type: "select",
    subtype: "multiple",
    optionSource: "AccountTypeCode",
    optionFilterRule: {"CUST": true, "FCUST": true, "PROSP": true},
  },

]

const AddDynamicPlan: React.FC<ListAddDynamicProps> = ({list, setEditedList}) => {
  return(
    <div className="p-3">
      Find
      <strong> Plans </strong>
      that match the following
      <div className="pl-3">
        {PlanInput.map(({property, label, type, subtype, optionSource, searchTypes, optionFilterRule}, idx) => {
          let propertyVal: any, onChangeCallback:(value: any) => any
          propertyVal = get(list, property)
          onChangeCallback = (value:any) => setEditedList((editedList) => {
            if(!editedList) return editedList
            let updatedList = cloneDeep(editedList)
            let returnValue = value
            if(label === "Owner") {
              if(subtype === "single"){
              } else {
                if(Array.isArray(value)) {
                  returnValue = uniq(union(propertyVal, value))
                }else {
                  returnValue = uniq(union(propertyVal, [value]))
                }
              }
            }
            if (type === "select" && subtype === "multiple") returnValue = returnValue.map((v:any) => v.code)
            set(updatedList, property, returnValue)
            return updatedList
          })
          if(label === "Owner") {
            return (
              <React.Fragment key={idx}>
                <EmployeePicker
                  managerId={244}
                  onClick={onChangeCallback}
                  dropdownClass={"form-dropdown not-full-width row"}
                >
                  <DropdownToggle className="data-dropdown w-100 px-3 py-0 border-0 shadow-none">
                    <Row className="form-group">
                      <Label
                        className={"col-form-label col-sm-4"}
                        for={`1-list-select`}
                        id={"list.id"}
                      >
                        <div
                          className={"d-flex w-100"}
                        >
                          Owner
                        </div>
                      </Label>
                      <div className={"col-sm-8"}>
                        <div className={classNames("pl-1 ml-1 fake-input form-control form-control-sm")} title={""}>
                          {""}
                        </div>
                        <div className='pl-1 ml-0 row'>
                          {propertyVal.map((item: Person, idx: number) => {
                            let name = getPersonName(item)
                            let id = item?.id
                            const handleClick = (event: any) => {
                              event?.stopPropagation()
                              let newPropertyValue = cloneDeep(propertyVal)
                              newPropertyValue.splice(idx,1)
                              setEditedList((editedList) => {
                                if(!editedList) return editedList
                                let updatedList = cloneDeep(editedList)
                                let returnValue = newPropertyValue
                                set(updatedList, property, returnValue)
                                return updatedList
                              })
                            }
                            return(
                              <Button color="light" size="sm" className="mr-1" key={item.__typename + id} onClick={handleClick}>
                                {name}
                                <FontAwesomeIcon
                                  icon="times"
                                  className="pl-2"
                                />
                              </Button>
                            )
                          })}
                        </div>
                      </div>
                    </Row>
                  </DropdownToggle>
                </EmployeePicker>
              </React.Fragment>
            )
          }
          return(
            <FormInput
              key={idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              idx={idx}
              editMode={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              optionFilterRule={optionFilterRule}
              searchTypes={searchTypes}
              defaultOptions={Array.isArray(propertyVal) ? propertyVal?.map((pv:any) => ({code: pv})) : propertyVal}
            />
          )
        })}
      </div>
    </div>
  )
}

const VehicleInput: FormInputField[] = [
  {
    property: "dynamicFilters.vehicle.product",
    label: "Product",
    type: "search",
    searchTypes: [SearchTypes.Product],
  },
  {
    property: "dynamicFilters.vehicle.category",
    label: "Category",
    type: "select",
    subtype: "multiple",
    optionSource: "VehicleCategoryCode",
  },
  {
    property: "dynamicFilters.vehicle.status",
    label: "Status",
    type: "select",
    subtype: "multiple",
    optionSource: "VehicleStatusCode",
  },
]


const AddDynamicVehicle: React.FC<ListAddDynamicProps> = ({list, setEditedList}) => {
  return(
    <div className="p-3">
      Find
      <strong> Vehicles </strong>
      that match the following
      <div className="pl-3">
        {VehicleInput.map(({property, label, type, subtype, optionSource, searchTypes}, idx) => {
          let propertyVal, onChangeCallback
          propertyVal = get(list, property)
          onChangeCallback = (value:any) => setEditedList((editedList) => {
            if(!editedList) return editedList
            let updatedList = cloneDeep(editedList)
            let returnValue = value
            if (type === "select" && subtype === "multiple") returnValue = returnValue.map((v:any) => v.code)
            set(updatedList, property, returnValue)
            return updatedList
          })
          return(
            <FormInput
              key={idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              idx={idx}
              editMode={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              searchTypes={searchTypes}
              defaultOptions={Array.isArray(propertyVal) ? propertyVal?.map((pv:any) => ({code: pv})) : propertyVal}
            />
          )
        })}
      </div>
    </div>
  )
}

const OrgInput: FormInputField[] = [
  {
    property: "dynamicFilters.org.owner",
    label: "Owner",
    type: "search",
    searchTypes: [SearchTypes.People],
  },
  {
    property: "dynamicFilters.org.type",
    label: "Type",
    type: "select",
    subtype: "multiple",
    optionSource: "OrgTypeCode",
  },
  {
    property: "dynamicFilters.org.subType",
    label: "Sub Type",
    type: "select",
    subtype: "multiple",
    optionSource: "OrgSubTypeCode",
  },
  {
    property: "dynamicFilters.org.accountType",
    label: "Account Type",
    type: "select",
    subtype: "multiple",
    optionSource: "AccountTypeCode",
    optionFilterRule: {"CUST": true, "FCUST": true, "PROSP": true},
  },
]

const AddDynamicOrg: React.FC<ListAddDynamicProps> = ({list, setEditedList}) => {
  return(
    <div className="p-3">
      Find
      <strong> Organizations </strong>
      that match the following
      <div className="pl-3">
        {OrgInput.map(({property, label, type, subtype, optionSource, searchTypes, optionFilterRule}, idx) => {
          let propertyVal: any, onChangeCallback: (value: any) => any
          propertyVal = get(list, property)
          onChangeCallback = (value:any) => setEditedList((editedList) => {
            if(!editedList) return editedList
            let updatedList = cloneDeep(editedList)
            let returnValue = value
            if(label === "Owner") {
              if(subtype === "single"){
              } else {
                if(Array.isArray(value)) {
                  returnValue = uniq(union(propertyVal, value))
                }else {
                  returnValue = uniq(union(propertyVal, [value]))
                }
              }
            }
            if (type === "select" && subtype === "multiple") returnValue = returnValue.map((v:any) => v.code)
            set(updatedList, property, returnValue)
            return updatedList
          })
          if(label === "Owner") {
            return (
              <React.Fragment key={idx}>
                <EmployeePicker
                  managerId={244}
                  onClick={onChangeCallback}
                  dropdownClass={"form-dropdown not-full-width row"}
                >
                  <DropdownToggle className="data-dropdown w-100 px-3 py-0 border-0 shadow-none">
                    <Row className="form-group">
                      <Label
                        className={"col-form-label col-sm-4"}
                        for={`1-list-select`}
                        id={"list.id"}
                      >
                        <div
                          className={"d-flex w-100"}
                        >
                          Owner
                        </div>
                      </Label>
                      <div className={"col-sm-8"}>
                        <div className={classNames("pl-1 ml-1 fake-input form-control form-control-sm")} title={""}>
                          {""}
                        </div>
                        <div className='pl-1 ml-0 row'>
                          {propertyVal.map((item: Person, idx: number) => {
                            let name = getPersonName(item)
                            let id = item?.id
                            const handleClick = (event: any) => {
                              event?.stopPropagation()
                              let newPropertyValue = cloneDeep(propertyVal)
                              newPropertyValue.splice(idx,1)
                              setEditedList((editedList) => {
                                if(!editedList) return editedList
                                let updatedList = cloneDeep(editedList)
                                let returnValue = newPropertyValue
                                set(updatedList, property, returnValue)
                                return updatedList
                              })
                            }
                            return(
                              <Button color="light" size="sm" className="mr-1" key={item.__typename + id} onClick={handleClick}>
                                {name}
                                <FontAwesomeIcon
                                  icon="times"
                                  className="pl-2"
                                />
                              </Button>
                            )
                          })}
                        </div>
                      </div>
                    </Row>
                  </DropdownToggle>
                </EmployeePicker>
              </React.Fragment>
            )
          }
          return(
            <FormInput
              key={idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              idx={idx}
              editMode={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              optionFilterRule= {optionFilterRule}
              searchTypes={searchTypes}
              defaultOptions={Array.isArray(propertyVal) ? propertyVal?.map((pv:any) => ({code: pv})) : propertyVal}
            />
          )
        })}
      </div>
    </div>
  )
}

const PortfolioInput = (assetClassesOptions:{ code: string; value: string; }[] | undefined): FormInputField[] => {
  return [
    {
      property: "dynamicFilters.portfolio.plan",
      label: "Plan",
      type: "search",
      searchTypes: [SearchTypes.Plan],
    },
    {
      property: "dynamicFilters.portfolio.composite",
      label: "Composite",
      type: "checkbox",
      subtype: "boolean",
    },
    {
      property: "dynamicFilters.portfolio.dataType",
      label: "Data Type",
      type: "select",
      subtype: "multiple",
      optionSource: "PortfolioDataType",
    },
    {
      property: "dynamicFilters.portfolio.assetClass",
      label: "Asset Class",
      type: "select",
      subtype: "multiple",
      options: assetClassesOptions,
    },
    {
      property: "dynamicFilters.portfolio.parentMix",
      label: "Parent Mix",
      type: "select",
      subtype: "multiple",
      options: assetClassesOptions,
    },
  ]
}


const AddDynamicPortfolio: React.FC<ListAddDynamicProps> = ({list, setEditedList, assetClassesOptions, assetClassesLoading}) => {
  return(
    <div className="p-3">
      Find
      <strong> Portfolios </strong>
      that match the following
      <div className="pl-3">
        {PortfolioInput(assetClassesOptions).map(({property, label, type, subtype, optionSource, searchTypes, options}, idx) => {
          let propertyVal, onChangeCallback, defaultOptions
          propertyVal = get(list, property)
          onChangeCallback = (value:any) => setEditedList((editedList) => {
            if(!editedList) return editedList
            let updatedList = cloneDeep(editedList)
            let returnValue = value
            if (type === "select" && subtype === "multiple"){
              if(options){
                // only things that are asset classes
                returnValue = returnValue.map((v:any) => ({__typename: "AssetClass", id: parseInt(v.code), code:  parseInt(v.code), value: v.value}))
              } else {
                returnValue = returnValue.map((v:any) => v.code)
              }
            }
            set(updatedList, property, returnValue)
            return updatedList
          })
          if(options){
            // only things that are asset classes
            propertyVal = propertyVal.map((pv:any) => ({code: pv.id.toString(), value: pv.fullName}))
            defaultOptions = propertyVal
          } else {
            defaultOptions = Array.isArray(propertyVal) ? propertyVal?.map((pv:any) => ({code: pv})) : undefined
          }
          return(
            <FormInput
              key={!!options ? `${idx}:${assetClassesLoading}` : idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              idx={idx}
              editMode={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              options={options}
              searchTypes={searchTypes}
              defaultOptions={defaultOptions}
            />
          )
        })}
      </div>
    </div>
  )
}


const ProductInput = (assetClassesOptions:{ code: string; value: string; }[] | undefined): FormInputField[] => {
  return [
    {
      property: "dynamicFilters.product.manager",
      label: "Manager",
      type: "search",
      searchTypes: [SearchTypes.Manager],
    },
    {
      property: "dynamicFilters.product.assetClass",
      label: "Asset Class",
      type: "select",
      subtype: "multiple",
      options: assetClassesOptions,
    },
    {
      property: "dynamicFilters.product.category",
      label: "Category",
      type: "select",
      subtype: "multiple",
      optionSource: "VehicleCategoryCode",
    },
    {
      property: "dynamicFilters.closedEndedProductListFilters.lastFund",
      label: "Last Fund",
      type: "checkbox",
      subtype: "boolean",
    },
    {
      property: "dynamicFilters.closedEndedProductListFilters.vintageYearFirstCashFlow.startDate",
      label: "Vintage Year First Cash Flow Start",
      type: "date",
    },
    {
      property: "dynamicFilters.closedEndedProductListFilters.vintageYearFirstCashFlow.endDate",
      label: "Vintage Year First Cash Flow End",
      type: "date",
    },
  ]
}


const AddDynamicProduct: React.FC<ListAddDynamicProps> = ({list, setEditedList, assetClassesOptions, assetClassesLoading}) => {
  return(
    <div className="p-3">
      Find
      <strong> Products </strong>
      that match the following
      <div className="pl-3">
        {ProductInput(assetClassesOptions).map(({property, label, type, subtype, optionSource, searchTypes, options}, idx) => {
          let propertyVal, onChangeCallback, defaultOptions
          propertyVal = get(list, property)
          onChangeCallback = (value:any) => setEditedList((editedList) => {
            if(!editedList) return editedList
            let updatedList = cloneDeep(editedList)
            let returnValue = value
            if (type === "select" && subtype === "multiple"){
              if(options){
                // only things that are asset classes
                returnValue = returnValue.map((v:any) => ({__typename: "AssetClass", id: parseInt(v.code), code:  parseInt(v.code), value: v.value}))
              } else {
                returnValue = returnValue.map((v:any) => v.code)
              }
            }
            set(updatedList, property, returnValue)
            return updatedList
          })
          if(options){
            // only things that are asset classes
            propertyVal = propertyVal.map((pv:any) => ({code: pv.id.toString(), value: pv.fullName}))
            defaultOptions = propertyVal
          } else {
            defaultOptions = Array.isArray(propertyVal) ? propertyVal?.map((pv:any) => ({code: pv})) : undefined
          }
          return(
            <FormInput
              key={!!options ? `${idx}:${!!assetClassesOptions && assetClassesOptions.length > 0}` : idx}
              property={property}
              displayName={label}
              type={type}
              subtype={subtype}
              idx={idx}
              editMode={true}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
              optionSource={optionSource}
              options={options}
              searchTypes={searchTypes}
              defaultOptions={defaultOptions}
            />
          )
        })}
      </div>
    </div>
  )
}

interface ListAddCompositeProps {
  list: ListDetailFragment
  setEditedList: React.Dispatch<React.SetStateAction<ListDetailFragment | undefined>>
}

type CompositeType = "Include" | "Exclude"

type ListCompositeType = ListDetailIncludeListFragment & {
  compositeType: CompositeType
}

const ListAddComposite: React.FC<ListAddCompositeProps> = ({ list, setEditedList }) => {
  const currentExclude = list.excludeList?.filter((el) => el.id !== list.primaryExcludeList?.id) || []
  const currentInclude = (list.includeLists || [])
  const addCompositeType = (list:ListDetailIncludeListFragment[], compositeType: CompositeType) => {
    return list.map((l) => ({...l, compositeType}))
  }
  let currentLists = [...addCompositeType(currentExclude, "Exclude") , ...addCompositeType(currentInclude, "Include")] as ListCompositeType[]
  let sortedCurrentLists = sortBy(currentLists, ['name'])
  let [search, setSearch] = useState<string>("")
  let [noneReturned, setNoneReturned] = useState<string | undefined>(undefined)

  let listsFilter: ListFilters = {limit: 100, name: search}
  const { loading, data, error, fetchMore } = useListSearchQuery({
    fetchPolicy: "cache-and-network",
    variables: { filters: listsFilter },
    skip: search.length === 0
  })

  const hasNextPage = !!data?.lists && !isEqual(noneReturned, search)

  const loadMore = () => {
    if(!data?.lists || loading){
      return
    }
    fetchMore({
      variables: { filters: {...listsFilter, offset: data.lists.length} },
    updateQuery: (previousResult:ListSearchQuery, { fetchMoreResult } :any) => {
      if(!fetchMoreResult || !previousResult){
        return previousResult
      }
      const previousResults = previousResult.lists
      let newResults = fetchMoreResult.lists
      if(newResults.length === 0){
        setNoneReturned(search)
      }
      if(!previousResults || !newResults){
        return previousResult
      }
      return {
        __typename: previousResult.__typename,
        lists: [...previousResults, ...newResults]
      }
    },
    })
  }

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  const onChange = (type: CompositeType | "Remove", searchList: ListSearchFragment | ListCompositeType) => {
    setEditedList((editedList)=> {
      if(!editedList){
        return editedList
      }
      let excludeList = cloneDeep(editedList.excludeList || [])
      let includeLists = cloneDeep(editedList.includeLists || [])
      if(type === "Remove") {
        remove(excludeList, (l) => l.id === searchList.id)
        remove(includeLists, (l) => l.id === searchList.id)
      } else if (type === "Exclude" && searchList.type === ListType.Static) {
        remove(includeLists, (l) => l.id === searchList.id)
        if(!find(excludeList, ["id", searchList.id])) excludeList.push(searchList)
      } else if (type === "Include") {
        remove(excludeList, (l) => l.id === searchList.id)
        if(!find(includeLists, ["id", searchList.id])) includeLists.push(searchList)
      }

      return {...editedList, excludeList, includeLists}
    })
  }

  return(
    <div className="list-sidebar-container add-items d-flex flex-column">
      <div>
        <ListGroup className="horizontal with-category has-includes">
          {sortedCurrentLists.length === 0 &&
            <div className='text-center'>
              Find and add complete lists.
              <br />
              You can include or exclude list members when adding them.
            </div>
          }
          {sortedCurrentLists.map((currentList, idx) => {
            return(
              <ListGroupItem className='p-2 border-bottom border-gray-20' tag="a" key={currentList.id}>
                <div className="w-100">
                  <Row noGutters>
                    <Col md="7">
                      <h3>{ currentList.name }</h3>
                      {/* <p>{permissionType.toLocaleUpperCase()}: {permissionText}</p> */}
                    </Col>
                    <Col md={4}>
                      <FormInput
                        key={currentList.id}
                        property={"compositeType"}
                        displayName={""}
                        type={"select"}
                        idx={idx}
                        editMode={true}
                        propertyVal={currentList.compositeType}
                        updateValue={(value:any) => onChange(value as CompositeType, currentList)}
                        options={GetLookupDataToOptions({
                          data: currentList.type === ListType.Static ? [
                            {
                              code: "Include",
                              value: "Include",
                            },
                            {
                              code: "Exclude",
                              value: "Exclude",
                            },
                          ] : [
                            {
                              code: "Include",
                              value: "Include",
                            },
                          ],
                          multiple: true,
                        })}
                      />
                    </Col>
                    <Col md="1">
                      <FontAwesomeIcon
                        className='ml-2 mt-2'
                        icon="trash"
                        size="lg"
                        onClick={() => onChange("Remove", currentList)}
                      />
                    </Col>
                  </Row>
                </div>
              </ListGroupItem>
            )
          })}
        </ListGroup>
      </div>
      <div>
        <div className='d-flex justify-content-between'>
          <div className='text-uppercase ml-2 my-2 text-gray-80'>
            Find & Add Lists
          </div>
        </div>
        <div className="w-100 pr-2">
          <DebouncedSearchBar
            setSearchTerm={(name) => setSearch(name)}
            idx={1}
            placeholder="Search for lists by name"
            shortProperty={"search"}
            property={"search"}
            editMode={true}
          />
        </div>
      </div>
      <div className='overflow-auto infinite-scroll-root flex-shrink-0 flex-grow' ref={rootRef}>
        {loading && !hasNextPage &&
          <PlaceHolder  height={100}/>
        }
        <ListGroup className="horizontal with-category has-includes">
          {data?.lists?.map((searchList, idx) => {
            if (!searchList) {
              return (<React.Fragment key={idx}></React.Fragment>)
            }
            let compositeType = find(sortedCurrentLists, ["id", searchList.id])?.compositeType
            return (
              <ListAddCompositeRow
                onChange={onChange}
                listItem={searchList}
                compositeType={compositeType}
              />
            )
          })}
        </ListGroup>

        {hasNextPage &&
          <div ref={infiniteRef}>
            <PlaceHolder height={200}/>
          </div>
        }
      </div>
    </div>
  )
}

interface ListAddCompositeRowProps {
  listItem: ListSearchFragment
  onChange: (type: CompositeType | "Remove", searchList: ListSearchFragment | ListCompositeType) => void
  compositeType?: CompositeType
}

const ListAddCompositeRow: React.FC<ListAddCompositeRowProps> = ({ listItem, onChange, compositeType }) => {
  let [modalOpen, setModalOpen] = useState(false)

  return(
    <ListGroupItem className='p-2 border-bottom border-gray-20' tag="a" key={listItem.id}>
      <div className="w-100">
        <Row noGutters>
          <Col md="7">
            <h3>{ listItem.name }</h3>
            {/* <p>{permissionType.toLocaleUpperCase()}: {permissionText}</p> */}
          </Col>
          <Col md={3}>
            <h5>List ID</h5>
            <p>{listItem.id}</p>
          </Col>
          <Col md="2">
            <p>{compositeType}{compositeType && "d"}</p>
          </Col>
        </Row>
      </div>
      <div className="hover-overlay">
        <Button className="quick-link" color='link' onClick={() => setModalOpen(true)}>
          <FontAwesomeIcon
            icon="external-link"
            size="xs"
            className="mr-2"
          />
          Quick View
        </Button>
        <Button className="include-button" onClick={() => onChange("Include", listItem)}>
          Include
        </Button>
        {listItem.type === ListType.Static &&
          <Button className="add-button" onClick={() => onChange("Exclude", listItem)}>
            Exclude
          </Button>
        }
      </div>
      <ListQuickView
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        listId={listItem.id}
      />
    </ListGroupItem>
  )
}

export default ListDetailAddItem