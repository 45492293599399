import React, { useContext, useState } from "react"
import { RouteComponentProps } from "react-router"
import { GridApi, GridReadyEvent } from "@ag-grid-community/core"
import Auth from "../../Auth/Auth"
import { ClientsListColumnDef } from "../../helpers/columnDefs"
import { appProps } from "../../queries/extended/appProps"
import { WithTopNav } from "../ui/LayoutWrapper"
import {
  AllClientsQuery,
  AllClientsQueryVariables,
  ListDetailFragment,
  ListDetailIncludeListFragment,
  ListFilters,
  ListSimpleFragment,
  ListSearchFragment,
  ListSearchQuery,
  ListType,
  OrgType,
  useAllClientsQuery,
  useListSearchQuery,
  useMeDepartmentQuery,
  useUpdateStaticListMembersMutation,
} from "../../__generated__/graphql"
import { Col, Container, Input, Row , DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, ListGroup, ListGroupItem, Button, Modal, ButtonDropdown} from "reactstrap"
import PlaceHolder from "../ui/PlaceHolder"
import _, { cloneDeep, find, isEqual, remove, sortBy, uniq } from "lodash"
import SortableTable from "../Shared/SortableTable"
import PagePermissionCheckFail from "../Shared/PagePermissionCheckFail"
import { AddListModal } from '../List/ListNewModal'
import useInfiniteScroll from "react-infinite-scroll-hook"
import { FormInput } from "../ui/Forms/FormInput"
import { GetLookupDataToOptions } from "../ui/LookupOptions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DebouncedSearchBar } from "../ui/Forms/SearchInput"
import { ListQuickView } from "../List/ListQuickView"
import ErrorDisplay from "../Shared/ErrorDisplay"
import { getRecentLists, getSessionCookie } from "../../helpers/session"
import { TemporaryAlertContext } from "../../Context/TemporaryAlertContext"
import {CsvExportLimitModal} from '../Shared/CsvExportLimitModal'

interface authProps {
  auth: Auth
}

interface QueryChildren extends React.HTMLProps<HTMLDivElement> {
  (result: AllClientsQuery & authProps & RouteComponentProps): React.ReactNode
  // any
}

const AllClientsComponent: React.FC<
  RouteComponentProps & QueryChildren & appProps
> = (props) => {
  const { auth } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  if (!auth.checkPermissions(["view:all_clients"])) {
    return <PagePermissionCheckFail />
  }
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const { loading:userLoading, error:userError, data:userData } = useMeDepartmentQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [filterText, setFilterText] = useState("")
  const [updateStaticListMembers] = useUpdateStaticListMembersMutation()
  const { addAlert } = useContext(TemporaryAlertContext)
  const [isAddToListDropdownOpen, setAddToListDropdown] = useState(false)
  const toggleAddToListDropdown = () => setAddToListDropdown(!isAddToListDropdownOpen)
  let tableData: any, colDef: any, onReady: (event: GridReadyEvent) => void
  const [csvExportModalOpen, setCsvExportModalOpen] = useState(false)
  const { loading, data, error } = useAllClientsQuery({
    variables: {
      type: ["Client"] as OrgType[],
      accountTypes: ["CUST", "FCUST"],
      offset: 0,
      limit: 3500,
    } as AllClientsQueryVariables,
    fetchPolicy: "cache-first",
  })
  
  const openCsvExportModal = () => {
    setCsvExportModalOpen(!csvExportModalOpen)
  }

  const exportToCsv =() => {
    let exportedRows = 0;
    gridApi?.exportDataAsCsv({
      onlySelected: gridApi?.getSelectedRows().length > 0,
      shouldRowBeSkipped: () => {
        exportedRows++
        if(exportedRows > 1000){
          openCsvExportModal()
        }
        return exportedRows > 1000
      }
    })
  }

  onReady = (event) => {
    setGridApi(event.api)
    event.api!.setFilterModel({
      "accountType.code": {
        filterType: "set",
        values: ["Active"],
      },
    })
  }
  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error) {
    return (
      <Container fluid>
        <ErrorDisplay error={error}/>
      </Container>
    )
  }
  if (!data) return <div>data doesn't exist</div>
  tableData = _.get(data, "orgs", [])
  let result: any[] = [] // plan list
  tableData.map((client: any) => {
    let plans = client.plans
    if (plans?.length > 0) {
      plans = plans.map((plan: any) => ({ ...plan, client }))
    }
    result = result.concat(plans)
  })
  colDef = ClientsListColumnDef()
  const recentLists = getRecentLists()
  const testList = gridApi?.getSelectedRows()
  const addClientToList = (listId:number): void => {
    const plans = uniq(gridApi?.getSelectedRows()?.map((row) => row.id))
    updateStaticListMembers({variables: {
      input: {
        id: listId,
        add: {plans}
      }
    }}).then(result => {
      addAlert({title: "Adding Client to List" ,message: `Client plans successfully added to list`, color: "success", timeout: 2000})
      gridApi?.deselectAll()
    }).catch(err => {
      addAlert({title: "Adding Client to List" ,message: `Client plans failed to be added to list`, color: "danger", timeout: 2000})

      console.error("Error Update list header:", err.message)
    })
  }
  return (
    <Container fluid className="d-flex flex-grow-1 flex-direction-column">
      <div className="mb-4">
        <h2 className="headline">Clients</h2>
      </div>
      <Row>
        <Col>
          <div className="pane pane-toolbar">
            <Input
              className="toolbar-search"
              type="text"
              placeholder="Find Clients by name"
              onChange={(event) => setFilterText(event.target.value)}
            />
            <ButtonDropdown className="userDropdown ml-4 p2" isOpen={isAddToListDropdownOpen} toggle={toggleAddToListDropdown} direction="down" >
              <DropdownToggle className="headline-dropdown bg-light add-to-composite-picker-dropdown" caret disabled={selectedRows.length === 0}>
                Add to List
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className="mr-2 bg-light border-gray-30 btn-thin" onClick={() => setIsOpen(!isOpen)} >
                  New List..
                </DropdownItem>
                <DropdownItem divider/>
                {
                  recentLists.map(list => 
                    <DropdownItem className="mr-2 bg-light border-gray-30 btn-thin" key={list.id} onClick={() => addClientToList(list.id)} disabled={list.sourceType !== ListType.Static}>
                      {
                        list.name
                      }
                    </DropdownItem>
                    )
                }
                <DropdownItem divider/>
                <DropdownItem className="mr-2 bg-light border-gray-30 btn-thin" onClick={() => setIsOpen2(!isOpen2)}>
                  Find List..
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
            <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
              Export CSV
              <img src='/assets/CSV.svg' className="ml-2"/>
            </Button>
          </div>
          <CsvExportLimitModal
            modalOpen={csvExportModalOpen}
            setModalOpen={setCsvExportModalOpen}
          />
        </Col>
      </Row>
      <SortableTable
        key={0}
        loading={loading}
        filterText={filterText}
        columnDefs={colDef}
        tableData={result}
        onReady={onReady}
        // for export as of date
        exportParams={{allColumns: true}}
        setSelectedRows={(rows) => setSelectedRows(rows)}
      />
      {user &&
        <AddListModal
          user={user}
          modalOpen={isOpen}
          setModalOpen={setIsOpen}
          auth={auth}
          forceValues={{type: ListType.Static}}
          items={{
            plans: uniq(gridApi?.getSelectedRows()?.map((row) => row.id)),
          }}
        />
        
      }
      <ListAddStatic        
        modalOpen={isOpen2}
        setModalOpen={setIsOpen2}
        addClientToList={addClientToList}
        
      />
    </Container>
  )
}
const ListAddStatic: React.FC<ListAddStaticProps> = ({ modalOpen, setModalOpen, addClientToList}) => {
  let [search, setSearch] = useState<string>("")
  let [noneReturned, setNoneReturned] = useState<string | undefined>(undefined)

  let listsFilter: ListFilters = {limit: 100, name: search, types:[ListType.Static]}
  const { loading, data, error, fetchMore } = useListSearchQuery({
    fetchPolicy: "cache-and-network",
    variables: { filters: listsFilter },
    skip: search.length === 0
  })

  const hasNextPage = !!data?.lists && !isEqual(noneReturned, search)

  const loadMore = () => {
    if(!data?.lists || loading){
      return
    }
    fetchMore({
      variables: { filters: {...listsFilter, offset: data.lists.length} },
    updateQuery: (previousResult:ListSearchQuery, { fetchMoreResult } :any) => {
      if(!fetchMoreResult || !previousResult){
        return previousResult
      }
      const previousResults = previousResult.lists
      let newResults = fetchMoreResult.lists
      if(newResults.length === 0){
        setNoneReturned(search)
      }
      if(!previousResults || !newResults){
        return previousResult
      }
      return {
        __typename: previousResult.__typename,
        lists: [...previousResults, ...newResults]
      }
    },
    })
  }

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

    const closeModal = () => {
    setModalOpen(false)
  }
  return(
    <Modal size="md" className="mt-5" isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} zIndex={1500}>
    <div className="list-sidebar-container add-items d-flex flex-column">
      <div>
        <div className='d-flex justify-content-between'>
          <div className='font-weight-bold lato ml-2 my-2 text-gray-100'>
            Find a List
          </div>
          <div className='mt-2' onClick={() => closeModal()}>
              <FontAwesomeIcon
                icon="times"
                className="ml-auto m"
              />
          </div>
        </div>
        <div className="w-100 pr-2">
          <DebouncedSearchBar
            setSearchTerm={(name) => setSearch(name)}
            idx={1}
            placeholder="Search for lists by name"
            shortProperty={"search"}
            property={"search-list"}
            editMode={true}
          />
        </div>
      </div>
      <div className='overflow-auto infinite-scroll-root flex-shrink-0 flex-grow' ref={rootRef}>
        {loading && !hasNextPage &&
          <PlaceHolder  height={100}/>
        }
        <ListGroup className="horizontal with-category has-includes">
          {data?.lists?.map((searchList, idx) => {
            if (!searchList) {
              return (<React.Fragment key={idx}></React.Fragment>)
            }
            return (
              <ListAddStaticRow
                listItem={searchList}
                addClientToList={addClientToList}
              />
            )
          })}
        </ListGroup>

        {hasNextPage &&
          <div ref={infiniteRef}>
            <PlaceHolder height={200}/>
          </div>
        }
      </div>
    </div>
  </Modal>
  )
}

interface ListAddStaticRowProps {
  listItem: ListSearchFragment
  addClientToList: (listId: number) => void
}

const ListAddStaticRow: React.FC<ListAddStaticRowProps> = ({ listItem, addClientToList }) => {
  let [modalOpen, setModalOpen] = useState(false)

  return(
    <ListGroupItem className='p-2 border-bottom border-gray-20' tag="a" key={listItem.id}>
      <div className="w-100">
        <Row noGutters>
          <Col md="7">
            <h3 className= 'text-gray-100'>{ listItem.name }</h3>
            <p className = 'text-gray-40'>{listItem.type}</p>
            {/* <p>{permissionType.toLocaleUpperCase()}: {permissionText}</p> */}
          </Col>
          <Col md={3}>
            <h5>List ID</h5>
            <p>{listItem.id}</p>
          </Col>
        </Row>
      </div>
      <div className="hover-overlay">
        <Button className="include-button" onClick={() => addClientToList(listItem.id)}>
          Add to list
        </Button>
      </div>
      <ListQuickView
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        listId={listItem.id}
      />
    </ListGroupItem>
  )
}

interface ListAddStaticProps {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  addClientToList: (listId: number) => void
}

export default WithTopNav(AllClientsComponent)
