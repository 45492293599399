import React, { Component, RefObject, useContext, useRef, useState } from 'react'
import _ from 'lodash'
import { Button, Col, Container, Row, Table } from 'reactstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconName } from "@fortawesome/fontawesome-svg-core"
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import iassign from 'immutable-assign'
import moment, { Moment } from 'moment'

import { appDate, CalendarContext } from '../../Context/CalendarContext'
import { AssetsByClient, AssetsByClientQuery, CategoryType, ClientType, ManagerAssetsByClientInput, ManagerAssetsInputFields, OriginCode, OtherAssetExplanation, useAssetsByClientQuery, useUpdateAssetsByClientMutation } from '../../__generated__/graphql'

import { CalendarPicker } from '../CalendarPicker'
import { DATE_API_FORMAT, FormInputField } from '../../helpers/constant'
import { FormInput, FormInputProps } from '../ui/Forms/FormInput'
import { listQuarters, ManagerType, numbroFormatter } from "../../helpers/helpers"
import { ManagerAssets_assets_Manager_assets_byClient } from '../../queries/types/ManagerAssets'
import Auth from '../../Auth/Auth'
import EditButtons from '../ui/EditButtons'
import PlaceHolder from './../ui/PlaceHolder'
import RouteLeavingGuard from '../Shared/RouteLeavingGuard'
import ErrorDisplay from '../Shared/ErrorDisplay'
import {AssetValidation} from '../Shared/ValidationModal'
import exportTables from '../../helpers/exportTable'

interface ManagerAssetClientProps {
  id: number
  managerType?: ManagerType
  auth: Auth
}

interface GroupedAssets {
  [key: string]: GroupedAsset
}

interface GroupedAsset {
  [key: string]: ManagerAssets_assets_Manager_assets_byClient
}

interface Props {
  setSearchDate: (searchDate:string) => void
  data: AssetsByClientQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  searchDate: string
  managerType?: ManagerType
  onChange: (oldState:AssetsByClientQuery, newState:AssetsByClientQuery) => void
}

interface HistoricalProps {
  data: AssetsByClientQuery
  editMode: boolean
  setEditMode: (mode:boolean) => void
  ref: RefObject<HistoricalResult>
  fetchMore: any
  managerType?: ManagerType
  onChange: (oldState:AssetsByClientQuery, newState:AssetsByClientQuery) => void
}

interface TotalAssets {
  [key: string]: {
    assetsUnderManagement: number
    numberOfAccounts: number
  }
}

const firstHistoricalDate = moment(appDate).subtract(5,"years")

const defaultStateDiff = {
  assetsByClient: [],
  otherAssetExplanation: [],
} as ManagerAssetsInputFields

const ManagerAssetsClientType: React.FC<ManagerAssetClientProps> = ({ id, managerType, auth }: ManagerAssetClientProps) => {
  const context = useContext(CalendarContext)
  const [searchDate, setSearchDate] = useState(context.quarter)
  const [screen, setScreen] = useState(context.period === "historical" ? "historical" :"single")
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const [updateManagerAssetsByClient] = useUpdateAssetsByClientMutation()
  const historicalResultRef = useRef<HistoricalResult>(null)
  const resultRef = useRef<Result>(null)
  const history = useHistory()
  let { data, loading, error, fetchMore } =
  useAssetsByClientQuery({
    variables: {
      id,
      startDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT),
      endDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT)
    },
    fetchPolicy: 'network-only'
    // partialRefetch: true,
  })
  const handleEdit = () => {
    historicalResultRef!.current?.resetForm()
    resultRef!.current?.resetForm()
    setStateDiff(defaultStateDiff)
    setEditMode(!editMode)
  }

  const [stateDiff, setStateDiff]:[ManagerAssetsInputFields, React.Dispatch<React.SetStateAction<ManagerAssetsInputFields>>] = useState(defaultStateDiff)

  const handleSubmit = () => {
    if(!auth.checkPermissions(["edit:manager"])){
      return
    }
    setSaving(true)
    let submissionData = {
      id: id,
      patch: stateDiff
    }

    updateManagerAssetsByClient({
      variables: {
        input: submissionData,
        startDate: screen === "single" ? searchDate : firstHistoricalDate.format(DATE_API_FORMAT),
        endDate: screen === "single" ? searchDate : appDate.format(DATE_API_FORMAT)
      }})
      .then(result => {
        setSaving(false)
        if (result && result.data) {
          setEditMode(false)
        }
        if (screen === "historical"){
          historicalResultRef.current?.resetHistory()
        }
      })
      .catch(err => {
        setSaving(false)
        console.error("Error testManagerSummary", err.message)
      })
  }

  const handleChange = (oldState:AssetsByClientQuery, newState:AssetsByClientQuery) => {
    if(oldState.assets && oldState.assets.__typename === "Manager" && newState.assets && newState.assets.__typename === 'Manager'){
      const { assetsByClient:newClientType, otherAssetExplanation:newOther } = newState.assets
      const { assetsByClient:oldClientType, otherAssetExplanation:oldOther } = oldState.assets
      let submissionData:ManagerAssetsInputFields = {
        assetsByClient: [],
        otherAssetExplanation: []
      }
      if (newClientType && oldClientType && !_.isEqual(newClientType, oldClientType)) {
        newClientType.map((account, index) => {
          if (account && !_.isEqual(account, oldClientType[index])) {
            let submittedAccounts:ManagerAssetsByClientInput = {
              quarterEndDate: account.quarterEndDate,
              clientType: account.clientType.code,
              categoryType: account.categoryType.code,
              assetsUnderManagement: account.assetsUnderManagement,
              numberOfAccounts: account.numberOfAccounts,
            }

            submissionData?.assetsByClient?.push(submittedAccounts as ManagerAssetsByClientInput)
          }
        })
      }

      if (newOther && oldOther && !_.isEqual(oldOther, newOther)) {
        newOther.map((account, index) => {
          if (account && !_.isEqual(account, oldOther[index])) {
            let submittedAccounts = {
              quarterEndDate: account.quarterEndDate,
              client: account?.client
            }

            submissionData?.otherAssetExplanation?.push(submittedAccounts)
          }
        })
      }

      setStateDiff(submissionData)
    }
  }

  let explanation = _.get(data, 'assets.otherAssetExplanation[0].client', "")
  const heading = (
    <>
      <RouteLeavingGuard
        when={editMode}
        navigate={path => history.push(path)}
      />
      <div className="pane pane-toolbar sticky-top">
        <CalendarPicker
          updateValue={(searchDate) => setSearchDate(searchDate)}
          hasHistorical={true}
          updateType={(type:string) => setScreen(type)}
          editMode={editMode}
          setEditMode={(value:boolean) => setEditMode(value)}
        />
        <div className="border-left ml-2 pl-2">
          <Button color="secondary btn-thin" className="mt-1 ml-1 text-callan-blue" onClick={()=> exportTables({extraRows: [{values:["Other Explanation",explanation]}]})}>
            Export CSV
            <img src='/assets/CSV.svg' className="ml-2"/>
          </Button>
        </div>
        {screen === "historical" &&
          <Button color="secondary" className="ml-2 btn-load-more" onClick={()=>historicalResultRef.current?.loadMore()}>
            {historicalResultRef.current?.state.loadingMore && "Loading"}
            {!historicalResultRef.current?.state.loadingMore && "Load 5 More Years"}
          </Button>
        }
        {auth.checkPermissions(["edit:manager"]) &&
          <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} cancelEdit={handleEdit}/>
        }
      </div>
    </>
  )

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            {heading}
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  if (error) {
    return (
      <Container fluid>
        <ErrorDisplay error={error}/>
      </Container>
    );
  }
  if (data && data.assets && data.assets.__typename === "Manager") {
    if(screen === "single"){
      return (
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <Result
                key={searchDate}
                ref={resultRef}
                editMode={editMode}
                setEditMode={setEditMode}
                setSearchDate={setSearchDate}
                data={data}
                searchDate={searchDate}
                managerType={managerType}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Container>
      )
    } else if(screen === "historical") {
      return (
        <Container fluid>
          <Row>
            <Col>
              {heading}
              <HistoricalResult
                managerType={managerType}
                ref={historicalResultRef}
                editMode={editMode}
                setEditMode={setEditMode}
                data={data}
                fetchMore={fetchMore}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Container>
      )
    }
  }
  return <div>data doesn't exist</div>
}

interface ResultState {
  currentState: AssetsByClientQuery
  initialState: AssetsByClientQuery
  validationModalOpen: boolean
}

class Result extends Component<Props> {
  state: ResultState = {
    currentState: this.props.data,
    initialState: this.props.data,
    validationModalOpen: false
  }

  resetForm = () => {
    this.setState({ ...this.state, currentState: this.state.initialState })
  }

  openValidationModal = (value:boolean) => {
    this.setState({ ...this.state, validationModalOpen: value})
  }
  
  static getDerivedStateFromProps(props: Props, state:ResultState) {
    return {
      currentState: props.editMode ? state.currentState : props.data,
      initialState: props.data,
    }
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    clientType: string,
    categoryType?: string
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["assets", table]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep((selectedTable || []) as AssetsByClient[])
        var selectedRow = _.find(rows, (o) => {return (o?.clientType.code === clientType) && (!categoryType || o?.categoryType.code === categoryType)})
        if(selectedRow){
          _.set(selectedRow, property, value);
        } else {
          const newRow: AssetsByClient= {
            assetsUnderManagement: null,
            categoryType: {code: (categoryType || "" )as CategoryType, __typename: "CategoryTypeLookup"},
            clientType: {code: (clientType || "") as ClientType, __typename: "ClientTypeLookup"},
            numberOfAccounts: null,
            quarterEndDate: this.props.searchDate,
            origin: { code: 'w' as OriginCode, __typename: 'OriginCodeLookup'},
            __typename: "AssetsByClient",
          }
          _.set(newRow, property, value);
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ currentState: newState }, () =>
      this.props.onChange(this.state.initialState, this.state.currentState)
    )
  }

  handleOtherChange = (
    value: any
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let newState = iassign(
      oldState,
      currentState => (currentState?.assets as any)?.otherAssetExplanation,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as OtherAssetExplanation[])
        var selectedRow = rows[0]
        if(selectedRow){
          _.set(selectedRow, "client", value);
        } else {
          const newRow: OtherAssetExplanation = {
            __typename: "OtherAssetExplanation",
            quarterEndDate: this.props.searchDate,
            client: value
          }
          rows.push(newRow)
        }
        return rows
      }
    )
    this.setState({ currentState: newState }, () =>
      this.props.onChange(this.state.initialState, this.state.currentState)
    )
  }

  render() {
    const managerType = this.props.managerType
    const assets = _.get(this.state.currentState, "assets.assetsByClient", [])
    const otherExplanation = _.get(this.state.currentState, 'assets.otherAssetExplanation', [] as OtherAssetExplanation[])
    const rowOrder = [
      {code: "CORP", display: "Corporate"},
      {code: "SUPAN", display: "Superannuation"},
      {code: "PUBLIC", display: "Public Fund (Gov)"},
      {code: "MULTI", display: "Union/Multi-Emp"},
      {code: "ENDOW", display: "Found&Endow"},
      {code: "HEALTH", display: "Health Care"},
      {code: "INSUR", display: "Insurance"},
      {code: "HINET", display: "High Net Worth"},
      {code: "WRAP", display: "Wrap Accounts"},
      {code: "SUBADV", display: "Sub-Advised"},
      {code: "SUPN", display: "Supranationals"},
      {code: "SOV", display: "Sov Wealth Funds"},
      {code: "OTHER", display: "Other"},
      {code: "TOT", display: "Total"}
    ]
    var totals: TotalAssets = {}
    const groupedAssets = assets.reduce(
      (
        result: GroupedAssets,
        entry: ManagerAssets_assets_Manager_assets_byClient
      ) => {
        result[entry.clientType.code] = result[entry.clientType.code] || []
        result[entry.clientType.code][entry.categoryType.code] = entry
        if (!["TOT", "DC", "US"].includes(entry.clientType.code)) {
          if (totals[entry.categoryType.code]) {
            totals[entry.categoryType.code] = {
              assetsUnderManagement:
                totals[entry.categoryType.code].assetsUnderManagement +
                (typeof entry?.assetsUnderManagement === 'string' ? parseFloat(entry?.assetsUnderManagement) : entry?.assetsUnderManagement || 0),
              numberOfAccounts:
                totals[entry.categoryType.code].numberOfAccounts +
                (typeof entry?.numberOfAccounts === 'string' ? parseFloat(entry?.numberOfAccounts) : entry?.numberOfAccounts || 0)
            }
          } else {
            totals[entry.categoryType.code] = {
              assetsUnderManagement: entry.assetsUnderManagement || 0,
              numberOfAccounts: entry.numberOfAccounts || 0
            }
          }
          return result
        }
        return result
      },
      {}
    )
    let headingColspan = 1
    if(managerType?.RA) headingColspan += 1
    if(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) headingColspan += 3

    return(
      <>
      <div className="pane pane-table">
        <div className="pane-title">
          <h3 id="assetsByClientTypesTooltipContainer">
            <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByClientTypeTooltip">
              Assets by Client Type
              <FontAwesomeIcon
                icon={"question-circle" as IconName}
                className="ml-2 mt-0"
                size="sm"
              />
            </div>
          </h3>
        </div>
        <div className="table-container">
          <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.assets?.name}-Assets by Client Type`}>
            <thead>
              <tr className="table-title row-border-olive-100">
                <th></th>
                <th colSpan={headingColspan} className="py-2">Assets ($M)</th>
                <th className="boundary-left"></th>
                <th className="boundary-right"></th>
                <th colSpan={headingColspan} className="py-2"># Accounts</th>
              </tr>
              <tr>
                <th>Client Type</th>
                <th className="text-right" id="assetsTotalTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="assetsTotalTooltip">
                    Total
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                  <>
                    <th className="text-right">Taxable</th>
                    <th className="text-right">Tax-Exempt</th>
                    <th className="text-right">Institutional</th>
                  </>
                }
                {managerType?.RA && (
                  <th className="text-right">Real Estate</th>
                )}
                <th className="boundary-left"></th>
                <th className="boundary-right"></th>
                <th className="text-right" id="accountsTotalTooltipContainer">
                  <div className="d-inline-flex align-items-center tooltip-icon" id="accountsTotalTooltip">
                    Total
                    <FontAwesomeIcon
                      icon={"question-circle" as IconName}
                      size="sm"
                    />
                  </div>
                </th>
                {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                  <>
                    <th className="text-right">Taxable</th>
                    <th className="text-right">Tax-Exempt</th>
                    <th className="text-right">Institutional</th>
                  </>
                }
                {managerType?.RA && (
                  <th className="text-right">Real Estate</th>
                )}
              </tr>
            </thead>
            <tbody>
              {rowOrder.map((arr, idx) => {
                return(
                  <AssetClassRow
                    data={groupedAssets}
                    row={idx}
                    key={idx}
                    editMode={this.props.editMode}
                    clientType={arr}
                    updateValue={(value: any, property: string, categoryType?: string) => this.handleInputChange(value, property, 'assetsByClient', arr.code, categoryType)}
                    managerType={managerType}
                    openValidationModal={this.openValidationModal}      />
                )
              })}
              <tr>
                <td>Calculated Total</td>
                <td className={"text-right"}>
                  {numbroFormatter(totals["TOT"]?.assetsUnderManagement,"$0,0.00")}
                </td>
                {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                  <>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["TAX"]?.assetsUnderManagement , "$0,0.00")}
                    </td>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["TAXE"]?.assetsUnderManagement , "$0,0.00")}
                    </td>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["INST"]?.assetsUnderManagement , "$0,0.00")}
                    </td>
                  </>
                }
                {managerType?.RA && (
                  <td className={"text-right"}>
                    {numbroFormatter(totals["RE"]?.assetsUnderManagement , "$0,0.00")}
                  </td>
                )}
                <td className="boundary-left"></td>
                <td className="boundary-right"></td>
                <td className={"text-right"}>
                  {numbroFormatter(totals["TOT"]?.numberOfAccounts , "0,0")}
                </td>
                {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                  <>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["TAX"]?.numberOfAccounts , "0,0")}
                    </td>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["TAXE"]?.numberOfAccounts , "0,0")}
                    </td>
                    <td className={"text-right"}>
                      {numbroFormatter(totals["INST"]?.numberOfAccounts , "0,0")}
                    </td>
                  </>
                }
                {managerType?.RA && (
                  <td className={"text-right"}>
                    {numbroFormatter(totals["RE"]?.numberOfAccounts , "0,0")}
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="pane pane-table">
        <div className="pane-title">
          <h3>Other Explanation</h3>
        </div>
        <div className={"m-3"}>
          <FormInput
            property={"client"}
            displayName={""}
            type={"textarea"}
            showZero={true}
            subtype={""}
            placeholder={""}
            idx={1}
            editMode={this.props.editMode}
            propertyVal={otherExplanation[0] && otherExplanation[0].client}
            updateValue={this.handleOtherChange}
          />
        </div>
      </div>
      {(managerType?.LGSH || managerType?.RA) &&
        <div className="pane pane-table">
          <div className="pane-title">
            <h3>Defined Contribution Assets</h3>
          </div>
          <div className="table-container">
            <Table hover className="table-bordered-internal exportable" data-export-name={`${this.props.data.assets?.name}-Defined Contribution Assets`}>
              <thead>
                <tr className="table-title row-border-olive-100">
                  <th></th>
                  <th colSpan={headingColspan} className="py-2">Assets ($M)</th>
                  <th className="boundary-left"></th>
                  <th className="boundary-right"></th>
                  <th colSpan={headingColspan} className="py-2"># Accounts</th>
                </tr>
                <tr>
                  <th>Client Type</th>
                  <th className="text-right">Total</th>
                  {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                    <>
                      <th className="text-right">Taxable</th>
                      <th className="text-right">Tax-Exempt</th>
                      <th className="text-right">Institutional</th>
                    </>
                  }
                  {managerType?.RA && (
                    <th className="text-right">Real Estate</th>
                  )}
                  <th className="boundary-left"></th>
                  <th className="boundary-right"></th>
                  <th className="text-right">Total</th>
                  {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                    <>
                      <th className="text-right">Taxable</th>
                      <th className="text-right">Tax-Exempt</th>
                      <th className="text-right">Institutional</th>
                    </>
                  }
                  {managerType?.RA && (
                    <th className="text-right">Real Estate</th>
                  )}
                </tr>
              </thead>
              <tbody>
                <AssetClassRow
                  data={groupedAssets}
                  row={1}
                  editMode={this.props.editMode}
                  clientType={{ code: "DC", display: "Defined Contribution" }}
                  updateValue={(value: any, property: string, categoryType?: string) => this.handleInputChange(value, property, 'assetsByClient', "DC", categoryType)}
                  managerType={managerType}
                  openValidationModal={this.openValidationModal}
                />
              </tbody>
            </Table>
            <AssetValidation
              modalOpen={this.state.validationModalOpen}
              setModalOpen={this.openValidationModal}
            />
          </div>
        </div>
      }
    </>
    )
  }
}

interface AssetClassRowProps {
  data: GroupedAssets
  row: number
  editMode: boolean
  clientType: {code: string, display: string}
  updateValue: (value:any, property:string, categoryType?:string) => void
  managerType?: ManagerType
  historicalDate?: string
  openValidationModal?: (value:boolean) => void
}

interface AssetClassInputField extends FormInputField{
  categoryType?: string
}

const AssetClassInputList:AssetClassInputField[] = [
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: "TOT"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: "TAX"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: "TAXE"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: "INST"},
  { property: "assetsUnderManagement", label: "", type: "number", subtype: "currency", categoryType: "RE"},
  { property: "boundary", label: "", type: "boundary"},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: "TOT"},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: "TAX"},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: "TAXE"},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: "INST"},
  { property: "numberOfAccounts", label: "", type: "number", categoryType: "RE"},
]

const AssetClassRow = ({data, row, editMode, clientType, updateValue, managerType, historicalDate, openValidationModal}: AssetClassRowProps) => {
  const onChange = (value:any, property: string, categoryType?: string) => {
    if(openValidationModal && value > 100_000_000){
      openValidationModal(true)
    }
    updateValue(value, property, categoryType)
  }
  return (
    <tr>
      <td className="text-nowrap">
        {!historicalDate && clientType.display}
        {!!historicalDate && historicalDate}
      </td>
      {AssetClassInputList.map(({property, label, type, subtype, placeholder, optionSource, readonly, categoryType}, idx) => {
        if(type === "boundary"){
          return (
            <React.Fragment key={idx}>
              <td className="boundary-left"></td>
              <td className="boundary-right"></td>
            </React.Fragment>
          )
        }
        let propertyVal//, onChangeCallback
        propertyVal = _.get(data, clientType.code+'.'+categoryType+'.'+property)
        const onChangeCallback = (value:any) => onChange(value, property, categoryType)
        if(categoryType === "TOT" || (categoryType === "RE" && managerType?.RA) || (["TAX", "TAXE", "INST"].includes(categoryType || "") && (managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF))){
          const subClasses: FormInputProps["subClasses"] = {}
          if(typeof propertyVal === "number" && propertyVal > 100_000_000){
            subClasses.inputClasses = "is-invalid"
          }
          return(
            <td key={idx} className="text-right">
              <FormInput
                property={property}
                displayName={label}
                type={type}
                showZero={true}
                subtype={subtype}
                subClasses={subClasses}
                placeholder={placeholder}
                idx={idx + (clientType.code || "")}
                editMode={editMode}
                propertyVal={propertyVal}
                updateValue={onChangeCallback}
                optionSource={optionSource}
                readonly={readonly}
              />
            </td>
          )
        }
      })}
    </tr>
  )
}

interface HistoricalGroupedAssets {
  [key: string]: { [key: string]: HistoricalGroupedAsset }
}

interface HistoricalGroupedAsset {
  [key: string] : ManagerAssets_assets_Manager_assets_byClient
}

interface HistoricalResultState {
  currentState: AssetsByClientQuery
  initialState: AssetsByClientQuery
  category: string
  displayValue: string
  historicalDate: Moment
  loadingMore: boolean
  validationModalOpen: boolean
}

class HistoricalResult extends Component<HistoricalProps> {
  state = {
    currentState: this.props.data,
    initialState: this.props.data,
    category: CategoryType["TOT"],
    displayValue: "assetsUnderManagement",
    historicalDate: moment(firstHistoricalDate),
    loadingMore: false,
    validationModalOpen: false
  }
  
  static getDerivedStateFromProps(props: HistoricalProps, state:HistoricalResultState) {
    const resetDate = state.historicalDate.valueOf() === moment(firstHistoricalDate).valueOf()
    return {
      currentState: resetDate && !props.editMode ? props.data : state.currentState,
      initialState: resetDate ? props.data : state.initialState,
      category: state.category,
      displayValue: state.displayValue,
      historicalDate: state.historicalDate,
      loadingMore: state.loadingMore,
      validationModalOpen: state.validationModalOpen,
    }
  }

  openValidationModal = (value:boolean) => {
    console.log("open", value, this.state)

    this.setState((state) => {return {...state, validationModalOpen: value, loadingMore: true}}, () => {
      console.log("after", this.state)
    })
  }

  resetForm = () => {
    this.setState({ currentState: this.state.initialState})
  }

  resetHistory = () => {
    this.setState({ historicalDate: moment(firstHistoricalDate) })
  }

  handleInputChange = (
    value: any,
    property: string,
    table: string,
    date: string,
    heading: string,
    category: string
  ) => {
    let oldState = _.cloneDeep(this.state.currentState)
    let path = ["assets", table]
    if(!_.get(oldState, path)) {
      _.set(oldState, path, [])
    }
    let newState = iassign(
      oldState,
      path,
      selectedTable => {
        let rows = _.cloneDeep(selectedTable as AssetsByClient[])
        var selectedRow = _.find(rows, (o) => {return (o.clientType.code === heading && o.quarterEndDate === date && o.categoryType.code === category)})
        if(selectedRow){
          _.set(selectedRow, property, value);
        } else {
          let newRow: any
          if( table === "assetsByClient" ){
            newRow = {
              __typename: "assetsByAssetClass",
              quarterEndDate: date,
              clientType: {
                __typename: "ClientTypeLookup",
                code: heading
              },
              categoryType: {
                __typename: "CategoryTypeLookup",
                code: category
              },
              assetsUnderManagement: null,
              numberOfAccounts: null,
            }
            _.set(newRow, property, value)
            rows.push(newRow)
          } else {
            console.error("Error: Object not found to update")
          }
        }
        return rows
      }
    )
    this.setState({currentState: newState },() =>
      this.props.onChange(this.state.initialState, this.state.currentState)
    )
  }

  setCategory = (category:CategoryType) => {
    this.setState({
      category: category
    })
  }

  setDisplayValue = (displayValue:string) => {
    this.setState({
      displayValue: displayValue
    })
  }

  loadMore = () =>{
    if(this.state.loadingMore){
      return
    }
    this.setState({loadingMore: true})
    this.props.fetchMore({
      variables: {
        id: this.state.currentState.assets?.id,
        startDate:  moment(this.state.historicalDate).subtract(5, "years").format(DATE_API_FORMAT),
        endDate: this.state.historicalDate.format(DATE_API_FORMAT)
      },
      updateQuery: (previousResult:AssetsByClientQuery, { fetchMoreResult } :any) => {
        if(!fetchMoreResult){
          return previousResult
        }
        const previousAssets = previousResult.assets
        const newAssets = fetchMoreResult.assets

        if(previousAssets?.__typename !== "Manager" || this.state.currentState.assets?.__typename !== "Manager" || this.state.initialState.assets?.__typename !== "Manager"){
          return
        }
        let otherAsset = previousAssets.otherAssetExplanation || []
        const returnedState = {
          assets: {
            id: this.state.currentState.assets?.id,
            assetsByClient: [ ...this.state.initialState.assets.assetsByClient, ...newAssets.assetsByClient],
            otherAssetExplanation: [...otherAsset, ...newAssets.otherAssetExplanation],
            managerTypes: newAssets.managerTypes,
            __typename: previousAssets.__typename
          },
        }
        const currentState = {
          assets: {
            id: this.state.currentState.assets?.id,
            assetsByClient: [...this.state.currentState.assets.assetsByClient, ...newAssets.assetsByClient],
            otherAssetExplanation: [...otherAsset, ...newAssets.otherAssetExplanation],
            managerTypes: newAssets.managerTypes,
            __typename: previousAssets.__typename
          },
        }
        this.setState({
          initialState: returnedState,
          currentState: currentState,
          historicalDate: moment(this.state.historicalDate).subtract(5, "years"),
          loadingMore: false
        })
        return previousResult;
      }
    })
  }

  render() {
    const category = this.state.category
    const displayValue = this.state.displayValue
    const headingOrder = [["CORP","Corporate"], ["SUPAN","Superannuation"], ["PUBLIC","Public Fund (Gov)"], ["MULTI","Union/Multi-Emp"], ["ENDOW","Found&Endow"], ["HEALTH","Health Care"], ["INSUR","Insurance"], ["HINET","High Net Worth"], ["WRAP","Wrap Accounts"], ["SUBADV","Sub-Advised"], ["SUPN","Supranationals"], ["SOV","Sov Wealth Funds"], ["OTHER","Other"]]
    const managerType = this.props.managerType
    const data = this.state.currentState
    const allDates = listQuarters(this.state.historicalDate.format(DATE_API_FORMAT), appDate.format(DATE_API_FORMAT))

    let headingColspan = 1
    if(managerType?.RA) headingColspan += 1
    if(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) headingColspan += 3
    let exportName = ""
    if(this.state.displayValue === "numberOfAccounts"){
      if(category === "TOT"){
        exportName = "Historical Total Accounts"
      }
      else if(category === "TAX"){
        exportName = "Historical Taxable Accounts"
      }
      else if(category === "TAXE"){
        exportName = "Historical Tax-Exempt Accounts"
      }
      else if(category === "INST")
      exportName = "Historical Institutional Accounts"
    }
    else {
      if(category === "TOT"){
        exportName = "Historical Total Assets"
      }
      else if(category === "TAX"){
        exportName = "Historical Taxable Assets"
      }
      else if(category === "TAXE"){
        exportName = "Historical Tax-Exempt Assets"
      }
      else if(category === "INST")
      exportName = "Historical Institutional Assets"
    }
    const heading = () =>{
      return(
      <div className="w-100 d-flex justify-content-between">
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-item",{ active: category === "TOT" })} onClick={() => this.setCategory(CategoryType["TOT"])}>Total</li>
          {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
            <>
              <li className={classnames("horizontal-picker-item",{ active: category === "TAX" })}  onClick={() => this.setCategory(CategoryType["TAX"])}>Taxable</li>
              <li className={classnames("horizontal-picker-item",{ active: category === "TAXE" })}  onClick={() => this.setCategory(CategoryType["TAXE"])}>Tax-Exempt</li>
              <li className={classnames("horizontal-picker-item",{ active: category === "INST" })}  onClick={() => this.setCategory(CategoryType["INST"])}>Institutional</li>
            </>
          }
          {this.props.managerType?.RA &&
            <li className={classnames("horizontal-picker-item",{ active: category === "RE" })}  onClick={() => this.setCategory(CategoryType["RE"])}>Real Estate</li>
          }
        </ul>
        <ul className={"horizontal-picker"}>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "assetsUnderManagement" })}  onClick={() => this.setDisplayValue("assetsUnderManagement")}>Assets ($M)</li>
          <li className={classnames("horizontal-picker-button",{ active: displayValue === "numberOfAccounts" })}  onClick={() => this.setDisplayValue("numberOfAccounts")}># Accounts</li>
        </ul>
      </div>)
    }

    const assets = _.get(data, 'assets.assetsByClient', [])
    var clientTypes:string[] = []
    const groupedAssets = assets.reduce((result:HistoricalGroupedAssets,entry:ManagerAssets_assets_Manager_assets_byClient) => {
      result[entry.quarterEndDate] = result[entry.quarterEndDate] || {};
      result[entry.quarterEndDate][entry.clientType.code] =  result[entry.quarterEndDate][entry.clientType.code] || {}
      result[entry.quarterEndDate][entry.clientType.code][entry.categoryType.code] = entry;
      if(clientTypes.indexOf(entry.clientType.code) === -1){
        clientTypes.push(entry.clientType.code)
      }
      return result;
    },{})
    const assetKeys:string[] = Object.keys(groupedAssets)
    console.log(this.state.validationModalOpen)
    return (
      <>
        <div className="pane pane-table">
          <div className="pane-title-alt">
            <h3 id="assetsByClientTypesHistoricalTooltipContainer">
              <div className="d-inline-flex align-items-center tooltip-icon" id="assetsByClientTypeHistoricalTooltip">
                Assets by Client Type
                <FontAwesomeIcon
                  icon={"question-circle" as IconName}
                  className="ml-2 mt-0"
                  size="sm"
                />
              </div>
            </h3>
          </div>
          <Table hover className="table-borderless table-condensed">
            <thead>
              <tr>
                <td colSpan={1000}>{heading()}</td>
              </tr>
            </thead>
          </Table>
          <div className="table-container">
            <Table hover className="table-bordered-internal table-condensed exportable" data-export-name={exportName}>
              <thead>
                <tr>
                  <th>Date</th>
                  {headingOrder.map((arr) => {
                    const key:string = arr[0]
                    const name:string = arr[1]
                    return(<th key={key}>{name}</th>)
                  })}
                  <th>Calculated Total</th>
                </tr>
              </thead>
              <tbody>
                {allDates.map((date:string, row:number) => {
                  const data = groupedAssets[date]
                  return (
                    <AssetClassHistoricalRow
                      date={date}
                      key={row + this.state.displayValue + this.state.category}
                      data={data}
                      row={row}
                      editMode={this.props.editMode}
                      updateValue={(value, property, heading, category) => this.handleInputChange(value,property,"assetsByClient",date,heading,category)}
                      category={this.state.category}
                      headingOrder={headingOrder}
                      displayValue={this.state.displayValue}
                      visibleDates={assetKeys}
                      openValidationModal={this.openValidationModal}
                    />
                  )
                  })}
              </tbody>
            </Table>
            <AssetValidation
                modalOpen={this.state.validationModalOpen}
                setModalOpen={this.openValidationModal}
              />
          </div>
        </div>
        {(managerType?.LGSH || managerType?.RA) &&
          <div className="pane pane-table">
            <div className="pane-title">
              <h3>Defined Contribution Assets</h3>
            </div>
            <div className="table-container">
              <Table hover className="table-bordered-internal table-condensed exportable" data-export-name={'Defined Contribution Assets-' + exportName}>
                <thead>
                  <tr className="table-title row-border-olive-100">
                    <th></th>
                    <th colSpan={headingColspan} className="py-2">Assets ($M)</th>
                    <th className="boundary-left"></th>
                    <th className="boundary-right"></th>
                    <th colSpan={headingColspan} className="py-2"># Accounts</th>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th className="text-right">Total</th>
                    {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                      <>
                        <th className="text-right">Taxable</th>
                        <th className="text-right">Tax-Exempt</th>
                        <th className="text-right">Institutional</th>
                      </>
                    }
                    {this.props.managerType?.RA && (
                      <th className="text-right">Real Estate</th>
                    )}
                    <th className="boundary-left"></th>
                    <th className="boundary-right"></th>
                    <th className="text-right">Total</th>
                    {(managerType?.LGSH || managerType?.RA || managerType?.HF || managerType?.HFFOF) &&
                      <>
                        <th className="text-right">Taxable</th>
                        <th className="text-right">Tax-Exempt</th>
                        <th className="text-right">Institutional</th>
                      </>
                    }
                    {this.props.managerType?.RA && (
                      <th className="text-right">Real Estate</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {allDates.map((date:string, row:number) => {
                    const data = groupedAssets[date]
                    return(
                      <AssetClassRow
                        key={row}
                        data={data}
                        row={row}
                        editMode={this.props.editMode}
                        clientType={{ code: "DC", display: "Defined Contribution" }}
                        updateValue={(value, property, category) => this.handleInputChange(value, property, "assetsByClient", date, "DC", (category || ""))}
                        managerType={this.props.managerType}
                        historicalDate={date}
                        openValidationModal={this.openValidationModal}/>
                    )
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        }
      </>
    )
  }
}

interface AssetClassHistoricalRowProps {
  data: any
  row: number
  editMode: boolean
  date: string
  headingOrder: any[]
  displayValue: string
  category: string
  visibleDates: string[]
  updateValue: (value:any, property:string, heading:string, category:string) => void
  openValidationModal?: (value:boolean) => void
}

const AssetClassHistoricalRow = ({data, row, editMode, headingOrder, updateValue, displayValue, date, category, visibleDates, openValidationModal }: AssetClassHistoricalRowProps) => {
  let rowTotal = 0
  const onChange = (value:any, displayValue: string, heading: any, category: string) => {
    if(openValidationModal && value > 100_000_000){
      openValidationModal(true)
    }
    updateValue(value, displayValue, heading, category)
  }
  return (
    <tr className="fadein">
      <td className="nowrap">
        {date}
      </td>
      {headingOrder.map((heading, idx) => {
        let propertyVal, onChangeCallback
        propertyVal = _.get(data, heading[0]+'.'+category+'.'+displayValue)
        onChangeCallback = (value:any) => onChange(value, displayValue, heading[0], category)
        rowTotal += propertyVal || 0
        let subClasses: FormInputProps["subClasses"] = {}
        if(typeof propertyVal === "number" && propertyVal > 100_000_000){
          subClasses.inputClasses = "is-invalid"
        }
        return(
          <td key={idx}>
            <FormInput
              property={displayValue}
              displayName={""}
              type={"number"}
              subClasses={subClasses}
              showZero={true}
              subtype={displayValue === "assetsUnderManagement" ? "currency" : ""}
              placeholder={""}
              idx={idx + (row*AssetClassInputList.length)}
              editMode={editMode}
              propertyVal={propertyVal}
              updateValue={onChangeCallback}
            />
          </td>
        )
      })}
      <td className="text-right">
        { displayValue === 'numberOfAccounts' ? numbroFormatter(rowTotal , "0,0" ) : numbroFormatter(rowTotal , "$0,0.00" )}
      </td>
    </tr>
  )
}

export default ManagerAssetsClientType
