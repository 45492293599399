import { useEffect, useState } from 'react'

import { AssetClassFragment } from '../__generated__/graphql'
import { ReportTab } from '../Components/Report/Shared/ReportComponent'
import { GetLookupDataToOptionsProps } from '../Components/ui/LookupOptions'

interface CurrentAssetClassFragmentProps extends AssetClassFragment {
  parent: AssetClassFragment
}

export const useAssetClassHooks = (
  data: GetLookupDataToOptionsProps["data"],
  currentAssetClass: CurrentAssetClassFragmentProps
) => {
  const [parent, setParent] = useState(currentAssetClass.parent)
  const [child, setChild] = useState(() => ({
    code: currentAssetClass.code
  }))
  return { parent, child }
}

export const useChildAssetClassHooks = (
  data: GetLookupDataToOptionsProps["data"],
  currentAssetClass: CurrentAssetClassFragmentProps
) => {
  const { parent } = useAssetClassHooks(data, currentAssetClass)

  let children = data.filter(assetClass => assetClass.code === parent.code)[0]
    .children

  return { children }
}

// Used to remember the current working tab in report components
export const useStoredTab = (componentId: number, tabs: ReportTab[]) => {
  const storeTab = (tab: ReportTab) => {
    const tabIndex = tabs.findIndex((t) => t.value === tab.value)
    localStorage.setItem(`Component-${componentId}-currentTab`, `${tabIndex}`)
  }

  const fetchTab = () => {
    const currentTabIndex = localStorage.getItem(`Component-${componentId}-currentTab`)
    return currentTabIndex ? tabs[parseInt(currentTabIndex, 10)] : null;
  }

  useEffect(()=>{
    return () => {
      localStorage.removeItem(`Component-${componentId}-currentTab`)
    }
  }, [])

  return { fetchTab, storeTab }
}
