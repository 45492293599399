import classnames from "classnames"
import { find } from "lodash"
import React, { EventHandler, Fragment } from "react"
import { InputProps } from "reactstrap"
import { CustomInputType } from "reactstrap/lib/CustomInput"
import {
  FormInputSubClasses,
  NumberInputTrailingStyleType
} from "../../../helpers/constant"
import { SearchTypes, Maybe } from "../../../__generated__/graphql"
import { GetLookupDataToOptions, GetLookupToOptionObject, GetLookupToOptions } from "../../ui/LookupOptions"
import { BooleanInput } from "./BooleanInput"
import { CheckboxBooleanInput } from "./CheckboxBooleanInput"
import { CustomInput } from "./CustomInput"
import { DateFieldInput } from "./DateFieldInput"
import { MultiSelectInput, OptionsProps } from "./MultiSelectInput"
import { NumberFieldInput, NumberFieldValidationProps } from "./NumberFieldInput"
import { RadioPillInput } from "./RadioPillInput"
import { RadioSelectInput } from './RadioSelectInput'
import { SearchInput } from "./SearchInput"
import { SelectInput } from "./SelectInput"
import { TextAreaInput } from "./TextAreaInput"
import { InputTooltip, TextFieldInput } from "./TextFieldInput"

export interface FormInputProps {
  property: string
  displayName?: string
  label?: string //alias for display name to make this compatible with the FormInputField type
  type: string
  subtype?: string //'multiple' | 'required' | 'boolean' | 'currency' TODO: Add all subtypes
  placeholder?: string
  idx: string | number
  editMode: boolean
  propertyVal: any
  updateValue: (event: any) => void
  inputRef?: React.RefObject<HTMLInputElement>
  handleEnter?: () => void
  optionSource?: string
  /** some options needs to be filtered*/
  optionFilterRule?: { [name: string]: boolean | number }
  /* use options params if data is queried in upper level*/
  options?: JSX.Element | {code: string, value: string}[] | null
  optionsSortFunction?: (a: any, b: any) => number
  readonly?: boolean
  rows?: number
  hidden?: boolean
  inline?: boolean
  subClasses?: { [name in FormInputSubClasses]?: string }
  tooltip?: InputTooltip
  infoTip?: InfoTipProps // description tooltip, attached to label/input
  required?: boolean
  noFormat?: boolean
  showZero?: boolean
  autoFocus?: boolean
  inputProps?: InputProps
  baseDecimalPlaces?: number
  editDecimalPlaces?: number
  nonNegative?: boolean
  defaultOptions?: OptionsProps[] | {code: string, value?: string}[]
  charactersLimit?: number // added for TextAreaInput & textFieldInput
  hideLimit?: boolean
  containerId?: string // add to monthpicker popover.container to show above modal
  searchTypes?: SearchTypes[]
  validateAlsoOnChange?: boolean
  staticText?: string
  optionHasLink?: boolean
  textLinkParams?: Maybe<{url: string | undefined}>
  displayTooltipAtValue?: boolean // example manager/overview/employees
  resetSearchOnViewMode?: boolean
  clearValueCallback?: (props?: any) => void
  dataValues?: {name: string, value: string}[]
  fieldValidations?: NumberFieldValidationProps
  immediateUpdate?: boolean
  debounceInput?: boolean
}

type InfoTipPlacement = 'top' | 'bottom' | 'left' | 'right'
type ParentElement = 'label' | 'input' | 'div'

export type InfoTipProps = {
  title: string // content
  parentElement?: ParentElement // attach to parent element
  placement?: InfoTipPlacement // position
  cssClasses?: { [name in FormInputSubClasses]?: string }
  enabledInViewMode?: boolean
}

export const FormInput: React.FC<FormInputProps> = (props) => {
  const {
    property,
    type,
    subtype,
    idx,
    editMode,
    propertyVal,
    optionSource,
    optionFilterRule,
    options,
    optionsSortFunction,
    readonly,
    handleEnter,
    rows,
    subClasses,
    tooltip,
    infoTip,
    noFormat,
    autoFocus,
    searchTypes,
    defaultOptions = [],
    inputRef,
    validateAlsoOnChange,
    staticText,
    displayTooltipAtValue,
    children,
    optionHasLink,
    dataValues,
    debounceInput,
  } = props
  let placeholder = props.placeholder || ""

  let wrapperClasses = subClasses?.wrapperClasses || ""
  let labelClasses = subClasses?.labelClasses || ""
  let inputWrapperClasses = subClasses?.inputWrapperClasses || ""
  let inputClasses = subClasses?.inputClasses || ""
  let displayName = props.displayName || props.label || ""
  let required: boolean = props.required || false

  let updateValue: EventHandler<any> = props.updateValue

  let additionalInputProps: InputProps | CustomInputType = {}
  let childOptions: any
  let childOptionsObject: any
  let currency: boolean = false
  let percent: boolean = false
  let year: boolean = false
  let trailing: NumberInputTrailingStyleType | undefined = undefined

  if(dataValues) {
    let dataObject:{[name: string]: string} = {}
    dataValues.map((dataValue)=> {
      dataObject["data-"+dataValue.name] = dataValue.value
    })
    additionalInputProps.dataObject = dataObject
  }

  const formatValue = (targetValue: any, type?: string) => {
    let value = targetValue
    if (type === "date" || type === "select" || type === "float") {
      if (targetValue === "") {
        value = null
      }
    }

    return value
  }

  const handleInputValueToStateValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    updateValue: (value: any) => void,
    type: string = "string"
  ) => {
    const targetValue = event.target.value
    let value = formatValue(targetValue, type)
    updateValue(value)
  }

  if (property === "") {
    // for section title use like productOverview-management
    if (displayName) {
      return (
        <div
          className={classnames(
            { "row form-section-title py-3": !wrapperClasses },
            wrapperClasses || ""
          )}
          key={idx}
        >
          {displayName}
        </div>
      )
    }
    return <div className="border-bottom-line-break row my-2" key={idx}></div>
  }

  if (property === "id" || readonly) {
    additionalInputProps.disabled = true
  }

  if (type && type === "select") {
    if (optionSource) {
      childOptions = GetLookupToOptions({
        name: optionSource,
        filterMap: optionFilterRule,
        multiple: subtype === "multiple" || subtype === "required",
        optionsSortFunction
      })

      childOptionsObject = GetLookupToOptionObject({
        name: optionSource,
        filterMap: optionFilterRule,
        optionsSortFunction
      })
    } else if (options) {
      if(Array.isArray(options)){
        childOptions = GetLookupDataToOptions({ data: options, hideBlank: required && !!find(options, ['code', propertyVal]), optionsSortFunction})
        childOptionsObject = options.map((o) => ({label: o.value, value: o.code}))
      } else {
        childOptions = options
        childOptionsObject = options
      }
    } else {
      childOptions = [<Fragment key={"-1"}></Fragment>]
    }

    if (subtype === "multiple") {

      const defaultOptionsArray = defaultOptions && defaultOptions.map((option: Maybe<OptionsProps> | any) => {
        return {
          label: !!option.value ? option.value : find(childOptionsObject, {value: option.code})?.label,
          value: option.code
        }
      })

      return (
        <MultiSelectInput
          idx={idx}
          property={property}
          displayName={displayName}
          editMode={editMode}
          multiple={subtype === "multiple"}
          onChange={updateValue}
          options={childOptionsObject}
          wrapperClasses={wrapperClasses}
          labelClasses={labelClasses}
          inputWrapperClasses={inputWrapperClasses}
          tooltip={tooltip}
          required={required}
          defaultOptions={defaultOptionsArray}
          {...additionalInputProps}
        />
      )
    }
    return (
      <SelectInput
        idx={idx}
        property={property}
        displayName={displayName}
        propertyValue={propertyVal}
        editMode={editMode}
        multiple={subtype === "multiple"}
        onChange={(event) => handleInputValueToStateValue(event, updateValue)}
        wrapperClasses={wrapperClasses}
        labelClasses={labelClasses}
        inputClasses={inputClasses}
        inputWrapperClasses={inputWrapperClasses}
        tooltip={tooltip}
        options={childOptions}
        required={required}
        inputRef={inputRef}
        {...additionalInputProps}
      />
    )
  } else if (type && type === "date") {
    return (
      <DateFieldInput
        idx={idx}
        property={property}
        displayName={displayName}
        propertyValue={propertyVal}
        editMode={editMode}
        placeholder={placeholder}
        updateValue={updateValue}
        wrapperClasses={wrapperClasses}
        labelClasses={labelClasses}
        inputClasses={classnames(inputClasses, readonly && "readonly")}
        inputWrapperClasses={inputWrapperClasses}
        infoTip={infoTip}
        tooltip={tooltip}
        required={required}
        inputProps={props.inputProps}
        {...additionalInputProps}
        inline
        subtype={subtype}
        inputRef={inputRef}
        containerId={props.containerId || ""}
        immediateUpdate={props.immediateUpdate}
      />
    )
  } else if (type && type === "textarea") {
    return (
      <TextAreaInput
        idx={idx}
        property={property}
        displayName={displayName}
        propertyValue={propertyVal}
        editMode={editMode}
        onChange={(event) => handleInputValueToStateValue(event, updateValue)}
        wrapperClasses={wrapperClasses}
        labelClasses={labelClasses}
        inputClasses={inputClasses}
        inputWrapperClasses={inputWrapperClasses}
        tooltip={tooltip}
        rows={rows}
        placeholder={placeholder}
        required={required}
        inputRef={inputRef}
        charactersLimit={props.charactersLimit}
      />
    )
  } else if (type && type === "radio") {
    if (subtype === "boolean") {
      return (
        <BooleanInput
          idx={idx}
          property={property}
          displayName={displayName}
          propertyValue={propertyVal}
          editMode={editMode}
          updateValue={updateValue}
          wrapperClasses={wrapperClasses}
          labelClasses={labelClasses}
          inputWrapperClasses={inputWrapperClasses}
          inputClasses={classnames(inputClasses, readonly && "readonly")}
          tooltip={tooltip}
          required={required}
          {...additionalInputProps}
        />
      )
    } else  if (subtype === "single") {
      return (
        <RadioSelectInput
          idx={idx}
          property={property}
          displayName={displayName}
          propertyValue={propertyVal}
          editMode={editMode}
          updateValue={updateValue}
          wrapperClasses={wrapperClasses}
          labelClasses={labelClasses}
          inputWrapperClasses={inputWrapperClasses}
          inputClasses={inputClasses}
          tooltip={tooltip}
          required={required}
          options={options}
          {...additionalInputProps}
        />
      )
    } else {
      if(options && Array.isArray(options)){
        return (
          <RadioPillInput
            idx={idx}
            property={property}
            displayName={displayName}
            propertyValue={propertyVal}
            editMode={editMode}
            updateValue={updateValue}
            wrapperClasses={wrapperClasses}
            labelClasses={labelClasses}
            inputWrapperClasses={inputWrapperClasses}
            inputClasses={classnames(inputClasses, readonly && "readonly")}
            tooltip={tooltip}
            required={required}
            options={options}
            {...additionalInputProps}
          />
        )
      }
    }
  } else if (type && type === "checkbox") {
    if (subtype === "boolean" || subtype === "show" || subtype === "on") {
      return (
        <CheckboxBooleanInput
          idx={idx}
          property={property}
          displayName={displayName}
          propertyValue={propertyVal}
          editMode={editMode}
          updateValue={updateValue}
          wrapperClasses={wrapperClasses}
          labelClasses={labelClasses}
          inputWrapperClasses={inputWrapperClasses}
          inputClasses={classnames(inputClasses, readonly && "readonly")}
          tooltip={tooltip}
          required={required}
          subtype={subtype}
          readonly={readonly}
          infoTip={infoTip}
          {...additionalInputProps}
        />
      )
    }
  }
  if (type && (type === "number" || type === "float")) {
    if (displayName === "Amount" || subtype === "currency") {
      currency = true
    }
    switch (subtype) {
      case "percent":
        percent = true
        break
      case "year":
        year = true
        break
      case "M":
        currency = true
        trailing = "M"
        break
      case "B":
        currency = true
        trailing = "B"
        break
      case "T":
        currency = true
        trailing = "T"
        break
      case "months":
        trailing = "months"
        break
      default:
        break
    }

    // use NumberFieldInput for float
    // TODO add input mask
    return (
      <NumberFieldInput
        idx={idx}
        property={property}
        displayName={displayName}
        propertyValue={propertyVal}
        editMode={editMode}
        placeholder={placeholder}
        type={type}
        subtype={subtype}
        currency={currency}
        percent={percent}
        trailing={trailing}
        year={year}
        updateValue={updateValue}
        wrapperClasses={wrapperClasses}
        labelClasses={labelClasses}
        inputWrapperClasses={inputWrapperClasses}
        inputClasses={classnames(inputClasses, readonly && "readonly")}
        infoTip={infoTip}
        tooltip={tooltip}
        required={required}
        noFormat={noFormat}
        showZero={props.showZero}
        inputProps={props.inputProps}
        baseDecimalPlaces={props.baseDecimalPlaces}
        editDecimalPlaces={props.editDecimalPlaces}
        nonNegative={props.nonNegative}
        inputRef={inputRef}
        charactersLimit={props.charactersLimit}
        fieldValidations={props.fieldValidations}
        {...additionalInputProps}
      />
    )
  }

  if (type && type === "search" && !!searchTypes) {
    return(
      <SearchInput
        idx={idx}
        property={property}
        displayName={displayName}
        propertyValue={propertyVal}
        editMode={editMode}
        placeholder={placeholder}
        updateValue={updateValue}
        wrapperClasses={wrapperClasses}
        labelClasses={labelClasses}
        inputWrapperClasses={inputWrapperClasses}
        inputClasses={classnames(inputClasses, readonly && "readonly")}
        tooltip={tooltip}
        required={required}
        subtype={subtype}
        autoFocus={autoFocus}
        inputProps={props.inputProps}
        searchTypes={searchTypes}
        inputRef={inputRef}
        staticText={staticText}
        optionHasLink={optionHasLink}
        resetSearchOnViewMode={props.resetSearchOnViewMode}
        clearValueCallback={props.clearValueCallback}
        textLinkParams={props.textLinkParams}
        {...additionalInputProps}
      />
    )
  }

  if (type && type === "custom" && !!children) {
    return(
      <CustomInput
        idx={idx}
        property={property}
        displayName={displayName}
        wrapperClasses={wrapperClasses}
        labelClasses={labelClasses}
        inputWrapperClasses={inputWrapperClasses}
        tooltip={tooltip}
        children={children}
        {...additionalInputProps}
      />
    )
  }

  return (
    <TextFieldInput
      idx={idx}
      property={property}
      displayName={displayName}
      propertyValue={propertyVal}
      editMode={editMode}
      placeholder={placeholder}
      currency={currency}
      trailing={trailing}
      updateValue={updateValue}
      handleEnter={handleEnter}
      wrapperClasses={wrapperClasses}
      labelClasses={labelClasses}
      inputWrapperClasses={inputWrapperClasses}
      inputClasses={classnames(inputClasses, readonly && "readonly")}
      infoTip={infoTip}
      tooltip={tooltip}
      required={required}
      subtype={subtype}
      autoFocus={autoFocus}
      inputProps={props.inputProps}
      charactersLimit={props.charactersLimit}
      hideLimit={props.hideLimit}
      inputRef={inputRef}
      validateAlsoOnChange={validateAlsoOnChange}
      textLinkParams={props.textLinkParams}
      displayTooltipAtValue={displayTooltipAtValue}
      debounceInput={debounceInput}
      {...additionalInputProps}
    />
  )
}
